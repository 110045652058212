import { getInstance } from "../auth/index";
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useCommentsStore } from "@/store/CommentsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";
import { useEllipseStore } from "@/store/EllipseStore.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import router from "@/router";

import csvtojson from "csvtojson";
import fs from "file-saver";

export const uploadToS3 = async function (
  files,
  path,
  id,
  notebookId,
  callback
) {
  const authService = getInstance();
  let curFile;
  const uploadProgresses = [];
  path = path.replace(/\/\/+/g, "/");
  for (let i = 0; i < files.length; i++) {
    curFile = files[i];
    await uploadFile(curFile);
  }

  async function uploadFile(file) {
    //MONGO ID IS INSERTED INTO THE FILE NAME TO MAKE IT UNIQUE
    //TODO - remove the mongo id from the file name, move to uplaod folders.
    const key = path + id + "?" + file.name;

    uploadProgresses.push({
      name: file.name,
      size: file.size,
      percent: 0,
      done: false,
    });

    const index = uploadProgresses.length - 1;

    callback(uploadProgresses);

    let response = await authService.$api.post(
      "/api/notebook/" + notebookId + "/file/s3/put",
      {
        key: key,
        fileType: file.type,
      }
    );

    let xhr = new XMLHttpRequest();
    xhr.open("PUT", response.data.data);
    xhr.upload.addEventListener("progress", uploadProgress, false);
    xhr.addEventListener("load", reqListener);

    function uploadProgress(evt) {
      const percentComplete = Math.round((evt.loaded * 100) / evt.total);
      uploadProgresses[index].percent = percentComplete;
      callback(uploadProgresses);
    }

    function reqListener() {
      xhr = this;
      // When finished uploading:
      if (xhr.status === 200) {
        uploadProgresses[index].done = true;
        callback(uploadProgresses, key);
      } else {
        // unsuccessful upload
      }
    }
    xhr.onerror = function () {
      // error handling
    };
    xhr.send(file);
  }
};

export const uploadModelsToS3 = async function (
  url,
  files,
  path,
  id,
  notebookId,
  callback
) {
  const authService = getInstance();
  let curFile;
  const uploadProgresses = [];
  path = path.replace(/\/\/+/g, "/");
  for (let i = 0; i < files.length; i++) {
    curFile = files[i];
    await uploadFile(curFile);
  }

  async function uploadFile(file) {
    //const key = path + file.name;
    //TODO - remove the mongo id from the file name, move to uplaod folders.
    const key = path + id + "?" + file.name;
    
    uploadProgresses.push({
      name: file.name,
      size: file.size,
      percent: 0,
      done: false,
    });

    const index = uploadProgresses.length - 1;

    callback(uploadProgresses);

    let response = await authService.$api.post(
      `${url}`, 
      {
      keys: [{ 
        key: key, 
        file_type: file.type 
      }],
    });
    let xhr = new XMLHttpRequest();
    xhr.open("PUT", response.data.data[0].url);
    xhr.upload.addEventListener("progress", uploadProgress, false);
    xhr.addEventListener("load", reqListener);

    function uploadProgress(evt) {
      const percentComplete = Math.round((evt.loaded * 100) / evt.total);
      uploadProgresses[index].percent = percentComplete;
      callback(uploadProgresses);
    }

    function reqListener() {
      xhr = this;
      // When finished uploading:
      if (xhr.status === 200) {
        uploadProgresses[index].done = true;
        let fileName = file.name;
        callback(uploadProgresses, key, fileName);
      } else {
        // unsuccessful upload
      }
    }
    xhr.onerror = function () {
      // error handling
    };
    xhr.send(file);
  }
};

export const downloadFile = async function (file, notebookId) {
  const authService = getInstance();
  let response = await authService.$api.post(
    `/api/notebook/${notebookId}/file/get-s3-signed-urls/?urlType=${router.currentRoute._value.name}`,
    {
      key: file.s3Key,
    }
  );
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      var downloadUrl = URL.createObjectURL(xhttp.response);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = downloadUrl;
      a.download = file.name;
      a.click();
    }
  };
  xhttp.open("GET", response.data.url, true);
  xhttp.responseType = "blob";
  xhttp.send();
};

export const formToJson = function (jqForm) {
  return jqForm.serializeArray().reduce(function (data, x) {
    data[x.name] = x.value;
    return data;
  }, {});
};

export const RGBToHex = function (r, g, b, prefix = "#") {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return prefix + r + g + b;
}

export const clickOnPageFunction = async function (newPage) {
  const notebookPropsStore = useNotebookPropsStore()
  const assetsStore = useAssetsStore()
  const ellipseStore = useEllipseStore()
  const dataGraphicsStore = useDataGraphicsStore()

  notebookPropsStore.setLoadingScreen(true);

  if (notebookPropsStore.getSelectedPage._id !== newPage._id) {
    dataGraphicsStore.$reset()
    let state = notebookPropsStore.getAllPageStates.find((p) => p.page === newPage._id);

    if (newPage.parent === null) {
      //store layoutId
      let layoutId = newPage.layout;
      ellipseStore.setSelectedLayoutId(layoutId);
    }

    await notebookPropsStore.setSelectedPage(newPage)

    notebookPropsStore.$patch({ selectedPageStateId: state._id })
  
    await assetsStore.setAllAssetsInNotebook();

    await notebookPropsStore.setGlobalProperty(state)

    await notebookPropsStore.setWidgets(state.widgets)

    if (assetsStore.getSelectedPageDatasets.length > 0) {
      if (state.selectedDataset) {
        await assetsStore.setSelectedPageDataset(state.selectedDataset)
      }
      else {
        await assetsStore.setSelectedPageDataset(assetsStore.getSelectedPageDatasets[0])
      }
    }
    notebookPropsStore.$patch({ widgetsToDelete: [] })
    notebookPropsStore.$patch({ widgetIDsToUpdate: [] })
  }
  notebookPropsStore.setLoadingScreen(false);
};

export const emptyPreviousState = async function () {
  const notebookPropsStore = useNotebookPropsStore()
  const dataGraphicsStore = useDataGraphicsStore()
  const assetsStore = useAssetsStore()
  const commentsStore = useCommentsStore()
  const ellipseStore = useEllipseStore()

  notebookPropsStore.$reset()
  commentsStore.$reset()
  assetsStore.$reset()
  dataGraphicsStore.$reset()

  ellipseStore.$patch({
    selectedlayoutId: null,
  })
};

export const saveCurrentState = async function () {
  const authService = getInstance();
  const notebookPropsStore = useNotebookPropsStore()
  const assetsStore = useAssetsStore()

  //array of new widgets to create
  let newWidgetsToAddInDatabase = await notebookPropsStore.getWidgets.filter(
    (e) => e.new === true
  );
  //array of already existing widgets
  let oldWidgets = await notebookPropsStore.getWidgets.filter((e) => e._id);

  //array of temporary deleted widgets
  let deletedWidgestIds = await notebookPropsStore.getWidgetsToDelete.map((e) => {
    return e._id;
  });
  if (
    newWidgetsToAddInDatabase.length === 0 &&
    deletedWidgestIds.length === 0
  ) {
    //when only global props updated
    if (notebookPropsStore.getGlobalPropertyUpdated) {
      let postData = {
        global_setting: notebookPropsStore.getGlobalProperty.global_setting,
      };
      let pageGlobalSettingUpdate = await authService.$api.post(
        `/api/pagestate/${notebookPropsStore.getSelectedPageStateId}/update_pagestate_global_setting`,
        postData
      );
      await notebookPropsStore.setGlobalProperty(pageGlobalSettingUpdate.data.data)

      let ids = notebookPropsStore.getWidgets.map((e) => e._id);
      await authService.$api.post(
        "/api/widget/update_local_setting",
        {
          ids: ids,
          is_locked: notebookPropsStore.getGlobalProperty.global_setting.is_locked,
          foreground_color:
            notebookPropsStore.getGlobalProperty.global_setting.foreground_color,
        }
      );
    }
    //when only widget setting properties updated
    if (notebookPropsStore.getwidgetIDsToUpdate.length) {
      //console.log("widget property updated");

      let widgetsToUpdate = []
      notebookPropsStore.getwidgetIDsToUpdate.forEach(id => {
        notebookPropsStore.getWidgets.forEach(w => {
          if (w._id === id) {
            widgetsToUpdate.push(w)
          }
        })
      })
      let postData = {
        widgets: widgetsToUpdate,
      };
      let update_widgets = await authService.$api.post(
        "/api/widget/update_widgets",
        postData
      );
      notebookPropsStore.setwidgetIDsToUpdate([])
    }
  }
  else {
    if (notebookPropsStore.getGlobalPropertyUpdated) {
      let postData = {
        global_setting: notebookPropsStore.getGlobalProperty.global_setting,
      };
      let pageGlobalSettingUpdate = await authService.$api.post(
        `/api/pagestate/${notebookPropsStore.getSelectedPageStateId}/update_pagestate_global_setting`,
        postData
      );
      await notebookPropsStore.setGlobalProperty(pageGlobalSettingUpdate.data.data)
    }
    //when add or delete widgets and properties updated
    //console.log("add or delete changes");
    let postData = {
      pageStateId: notebookPropsStore.getSelectedPageStateId,
      widget_add: newWidgetsToAddInDatabase,
      widget_delete: deletedWidgestIds,
      widgets: oldWidgets,
      updatedDataset: assetsStore.getSelectedPageDataset,
    };
    await authService.$api.post(`/api/pagestates/update_pagestate`, postData)
      .catch((err) => {
        console.log("Err in update_pagestate", err);
      });
  }
  notebookPropsStore.$patch({ globalPropertyUpdated: false })
  notebookPropsStore.setwidgetIDsToUpdate([])
  await authService.$api.get(
    `/api/pagestate/${notebookPropsStore.getSelectedPageStateId}/info`
  )
    .then((res) => {
      notebookPropsStore.setWidgets(res.data.data.widgets)
      notebookPropsStore.updateAllPageStates(res.data.data)
    })

  if (notebookPropsStore.getEditMode) {
    notebookPropsStore.toggleEditMode()
  }
}

export const setDigits = function (value, places) {
  const notebookPropsStore = useNotebookPropsStore()
  if (places === undefined) {
    places = notebookPropsStore.getGlobalProperty.global_setting.significant_digits
  }
  const factor = Math.pow(10, places);
  let val = value;
  if (!isNaN(value)) val = Math.round(value * factor) / factor;
  return val;
}

export const tabulatedAttributeToList = function (values, headers = undefined){
  let content = '';
  if(headers){
    values.forEach(value => {
      if (headers.includes(value.header)) content += "<strong style='color:white;'>" + value.header + "</strong> | " + value.attribute + "<br/>";
    });
  }
  else
  {
  values.forEach(value => {
    content += "<strong>" + value.header + "</strong> | " + value.attribute + "<br/>";
  });
  }
  return content;
}

export const tabulateAttributes = function (values, headers, varies = "varies") {
  const result = [];
  const cleaned = [];
  const notebookPropsStore = useNotebookPropsStore()
  let places = notebookPropsStore.getGlobalProperty.global_setting.significant_digits;
  const factor = Math.pow(10, places);
  
  if (headers && values) {
    // Initialize the result array with the first object
    headers.forEach((value) => {
      //Skip Ellipse Internal Keys
      if (value == null || value.name.startsWith("_") || value.name === "ellipseId") {
        return;
      }

      //Add Key to retult table. 
      var icon = 'mdi:mdi-pound';
      if (value.dataType != "Number") icon = 'mdi:mdi-format-text';
      result.push({ header: value.name, attribute: '', icon: icon });
    });

    if (values.length > 0) {

      result.forEach((header) => {
        for (let i = 0; i < values.length; i++) {
          const obj = values[i];

          if (obj !== null) {
            if (obj[header.header]) {
              let val = obj[header.header]
              if (!isNaN(val)) val = Math.round(val * factor) / factor;
              if (header.attribute === '') {
                header.attribute = val;
              }
              if (header.attribute != val) header.attribute = varies;
            }
          }
        };
        if (header.attribute != '') cleaned.push(header);
      });
    }

    let output = cleaned.sort(function (p) {
      var sortOrder = 1;
    
      if (p['header'][0] === "-") {
        sortOrder = -1;
        p['header'] = p['header'].substr(1);
      }
    
      return function (a, b) {
        if (sortOrder == -1) {
          return b[p['header']].localeCompare(a[p['header']]);
        } else {
          return a[p].localeCompare(b[p['header']]);
        }
      };
    });

    return output;
  }
  return result;
}

export const getColorByLabel = function (elemAttr, colorState, headers, name) {
  let values = "";
  if(colorState && elemAttr && elemAttr[0] && elemAttr[0]['ellipseId'] && colorState.ids.includes(elemAttr[0]['ellipseId'])){
      let index = colorState.ids.indexOf(elemAttr[0]['ellipseId']);
      let color = colorState.colors[index];

      var r, g, b, rgb;
      rgb = color.substring(4, color.length - 1).replace(/ /g, '').split(',');
      r = rgb[0];
      g = rgb[1];
      b = rgb[2];
      let textColor = '#ffffff';
      if (Math.sqrt( 0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) > 150) textColor = '#000000';

    values = "<span style='background-color: "+ textColor+"; color:"+colorState.colors[index]+"; border-radius: 6px; padding: 2px;' ><strong> &nbsp" + name + "</strong> | " + colorState.values[index] + "&nbsp </span><br/>"
    headers.splice(headers.indexOf(name),1)
  }
return {label:values,headers:headers};

}

export const sortByProperty = function (property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}

export const isDark = function (color) {
  var r, g, b, rgb, hsp;

  rgb = color.substring(4, color.length - 1)
    .replace(/ /g, '')
    .split(',');

  r = rgb[0];
  g = rgb[1];
  b = rgb[2];

  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  if (hsp > 150) {
    return '#000000';
  }
  else {

    return '#ffffff';
  }

}

export const timeStamp = function(){
  var date = new Date();
  return date.getFullYear() + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + (date.getDay()+1)).slice(-2) + "t" + ('0' + (date.getHours()+1)).slice(-2) + ('0' + (date.getMinutes()+1)).slice(-2) + ('0' + (date.getSeconds()+1)).slice(-2);
}

export const isNullOrUndefined = function(value){
  if (value !== "" && 
      value !== null && 
      value !== "null" &&
      value !== "undefined" &&
      value !== undefined) return false
  else return true
}

export const readJsonFile = async function (json) {

  return new Promise(async function (resolve, reject) {

  let reader = new FileReader();
  reader.readAsText(json, "UTF-8");

  reader.onload = async (evt) => {
    let text = evt.target.result;
    resolve(text);
    }
  });
}

export const csvToJson = async function (csv) {

  return new Promise(async function (resolve, reject) {
  let name = csv.name.replace(/\.[^/.]+$/, "");
  name = name + '_csv.json';

  let reader = new FileReader();
  reader.readAsText(csv, "UTF-8");

  reader.onload = async (evt) => {
    let text = evt.target.result;
    
  let json = await csvtojson().fromString(text)
      .then(async jsonObj => {
        
        const bytes = new TextEncoder().encode(JSON.stringify(jsonObj));
        const file = new File([bytes], name, { type: 'application/json' });
        return file;
      });
      
    resolve(json);
    }
  });
}
export const fileIcon = function (filename) {

  var re = /(?:\.([^.]+))?$/;
  var ext = re.exec(filename)[1];
  var icon = "fas fa-file";
  if (ext) {
    switch (ext) {
      case 'svg':
        icon = lexicon.drawing.icon;
        break;
      case '3dm':
        icon = lexicon.model.icon;
        break;
      case 'json':
        icon = lexicon.data.icon;
        break;
      case 'zip':
      case 'rar':
        icon = "fa-fw fas fa-file-zipper";
        break;
      case 'txt':
      case 'rtf':
        icon = "fa-fw fas fa-file-lines";
        break;
      case 'csv':
        icon = "fa-fw fas fa-file-csv";
        break;
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png':
      case 'bmp':
        icon = lexicon.image.icon;
        break;
      case 'tiff':
      case 'raw':
      case 'raw':
      case 'epw':
        icon = "fa-fw fas fa-file-image";
        break;
      case 'html':
      case 'js':
      case 'cs':
      case 'cs':
      case 'py':
        icon = "fa-fw fas fa-file-code";
        break;
      case 'pdf':
        icon = "fa-fw fas fa-file-pdf";
        break;
      case 'xls':
      case 'xlm':
      case 'xlsx':
      case 'xlsm':
        icon = "fa-fw fas fa-file-excel";
        break;
      case 'odt':
      case 'doc':
      case 'docx':
      case 'docm':
      case 'wbk':
        icon = "fa-fw fas fa-file-word";
        break;
      case 'ppt':
      case 'pps':
      case 'pptx':
      case 'pptm':
      case 'ppsx':
      case 'apxl':
      case 'knt':
        icon = "fa-fw fas fa-file-pdf";
        break;
      case 'mp4':
      case 'amv':
      case 'avi':
      case 'mov':
      case 'wmv':
        icon = "fa-fw fas fa-file-video";
        break;
      case 'mp3':
      case 'wav':
      case 'wma':
      case 'webm':
        icon = "fa-fw fas fa-file-audio";
        break;
        case 'geojson':
          icon = lexicon.map.icon;
          break;
    }
  }

  return icon;
}

export const getFileExtension = function (filename) {
  var re = /(?:\.([^.]+))?$/;
  var ext = re.exec(filename)[1];
  return ext;
}

export default {};
