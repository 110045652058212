<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

    <div v-if="getAttrData.length > 0 && grid_item.instance_setting.data" class="flexColumn">

      <div v-if="grid_item.instance_setting.data.filterValues" class="flexColumn">

        <v-container class="px-0 py-0 d-flex justify-space-between">
        
          <!-- Category. -->
          <div>
              <h5 class="WidgetSubHeader mr-2" 
                style="color: rgb(var(--v-theme-darkGrey));"
              >
                {{ this.grid_item.instance_setting.data.selectedAttribute }}
              </h5>
            </div>
            <v-btn-group density="compact" variant="flat" >

            <v-btn icon size="small" @click="selectNone">
                <v-icon color="darkGrey" >mdi:mdi-select-remove </v-icon>
                <v-tooltip activator="parent" location="top">Select None</v-tooltip>
            </v-btn>

            <v-btn icon size="small" @click="selectAll">
                <v-icon color="darkGrey" >mdi:mdi-select-all</v-icon>
                <v-tooltip activator="parent" location="top">Select All</v-tooltip>
            </v-btn>

            </v-btn-group>

        </v-container>
        <v-list 
          class="legandList pa-0"
          density="compact"
        >
          <v-infinite-scroll :items="dataChunk" @load="loadMoreData" direction="vertical" style="overflow-y:hidden">
            <v-list-item
              v-for="(item, index) in dataChunk" 
              :key="index"
              style="overflow-y: hidden;"
              class="mt-0 mb-0 pa-0"
              density="compact"
              size="x-small"
              min-height="20"
              label
              :ripple="false"
              height="20px"
            >
              <v-checkbox 
                v-model="item.show" 
                @update:modelValue="toggleFilterValue(index)"
                density="compact"
                color="darkSlate"
                hide-details="true"
                style="font-size: x-small;"
              >
                <template v-slot:label>
                  <span 
                    class="text-truncate text-caption"
                    style="font-size: small;"
                  >
                    {{ formatValue(item.value) }}
                  </span>
                </template>
              </v-checkbox>
            </v-list-item>
            <template v-slot:empty>
            </template>
          </v-infinite-scroll>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import * as utils from "@/utilities";
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getSelectedWidget,
      getDatasetLoading
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeaderOptions
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      updateFilterByProperties,
      addFilter
    } = dataGraphicsStore
    return {assetsStore, dataGraphicsStore,
      getEditMode, getAttrData, getAttrHeaderOptions, getSelectedPageDatasets, getSelectedWidget,
      getDatasetLoading, addFilter,
    updateFilterByProperties}
  },
  data() {
    return {
      //Selected header to filter by.
      selectedAttribute: "None",
      //Index of FilterValues to NOT filter out.
      filterSelection:[],
      //All Unique Values for selectedAttribute.
      //Schema: [{show:boolean, value:string}]
      filterValues: [],
      //Filter type for ellipseBus.
      filterType: "Value",
      toggle: null,
      dataChunk: [],
      itemsPerChunk: 50
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();
    if (!this.grid_item._id) {
      this.updateSelectedAttribute();
      this.filterByValues();
    } else {
      this.selectedAttributeCheck();
      this.filterByValues();
    }
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdate()
        }
      })
    })
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "removeAllFilters"){
          this.resetFilter()
        }
      })
    })
  },
  watch: {
    "grid_item.instance_setting.data.selectedAttribute": function() {
      this.propsUpdatedEvent()
    },
  },
  async unmounted() {
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {

      if (!this.grid_item._id) {
        this.grid_item.instance_setting.data = {
          selectedAttribute: this.getAttrHeaderOptions[0],
          filterValues: this.filterValues,
          filterType: this.filterType,
        };
      };
      
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!('selectedAttribute' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.selectedAttribute = this.selectedAttribute;
      if (!('filterValues' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filterValues = this.filterValues;
      if (!('filterType' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filterType = this.filterType;
      this.resetDataChunk()
    },
    propsUpdatedEvent(){
      if (this.getSelectedWidget &&
        this.getSelectedWidget.i === this.grid_item.i){
        this.updateSelectedAttribute();
        this.filterByValues();
        this.resetDataChunk()
      }
    },
    datasetUpdate(){
      this.updateSelectedAttribute();
      this.filterByValues();
      this.resetDataChunk()
    },
    resetDataChunk(){
      if (this.grid_item.instance_setting.data.filterValues){
        this.dataChunk = []
        this.dataChunk = this.grid_item.instance_setting.data.filterValues.slice(0, this.itemsPerChunk)
      }
    },
    formatValue(data) {
      return utils.setDigits(data);
    },
    toggleFilterValue() {
      this.filterByValues();
    },
    selectedAttributeCheck() {
      if (this.grid_item.instance_setting.data.selectedAttribute) {
        let result = this.getAttrHeaderOptions.filter(a => a === this.grid_item.instance_setting.data.selectedAttribute);
        if (result.length === 0) {
          this.grid_item.instance_setting.data.selectedAttribute = this.getAttrHeaderOptions[0];
          this.grid_item.instance_setting.datafilterValues = [];
          this.grid_item.instance_setting.filters = [];
        }
      }
    },
    updateSelectedAttribute() {
      this.selectedAttributeCheck();
      let selectedField = this.grid_item.instance_setting.data.selectedAttribute;
      if (selectedField === "None") {
        this.grid_item.instance_setting.data.filterValues = [];
      } else {
        this.grid_item.instance_setting.data.filterValues =
          this.createFilterValues(selectedField);
      }
    },
    createFilterValues(selectedField) {
      let newFilterValues = [];
      if (selectedField !== "None") {
        let fieldValues = [];
        this.getAttrData.forEach((attr) => {
          fieldValues.push(attr[selectedField]);
        });

        let uniqueFieldValues = evaluate.getUniqueVals(fieldValues);

        newFilterValues = uniqueFieldValues.map((item) => {
          const filter = {};
          filter.value = item;
          filter.show = true;
          return filter;
        });

        return newFilterValues;
      }
    },
    async filterByValues() {
      let filterObject = {
        filterType: "Value",
        field: this.grid_item.instance_setting.data.selectedAttribute,
        values: this.grid_item.instance_setting.data.filterValues,
      };
      this.grid_item.instance_setting.filters = [];
      this.grid_item.instance_setting.filters = [filterObject];

      if (filterObject.values.length > 0 && this.selectedAttribute != null) {
        let updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
        if (updateGlobalFilters) await this.updateFilterByProperties()
      }
    },
    selectAll() {
      var len = this.grid_item.instance_setting.data.filterValues.length;
      for (let i = 0; i < len; i++) {
        this.grid_item.instance_setting.data.filterValues[i].show = true;
        if (this.dataChunk[i]) this.dataChunk[i].show = true
      }
      this.filterByValues();
    },
    selectNone() {
      var len = this.grid_item.instance_setting.data.filterValues.length;
      for (let i = 0; i < len; i++) {
        this.grid_item.instance_setting.data.filterValues[i].show = false;
        if (this.dataChunk[i]) this.dataChunk[i].show = false
      }
      this.filterByValues();
    },
    loadMoreData({done}){
      if (this.dataChunk.length == this.grid_item.instance_setting.data.filterValues.length){
        done('empty')
        return
      }
      const startIndex = this.dataChunk.length
      const endIndex = startIndex + this.itemsPerChunk
      const newData = this.grid_item.instance_setting.data.filterValues.slice(startIndex, endIndex)
      this.dataChunk = this.dataChunk.concat(newData)
      done('ok')
    },
    resetFilter(){
      let valuesReset = []
      this.grid_item.instance_setting.data.filterValues.forEach(value => {
        value.show = true
        valuesReset.push(value)
      })
      this.grid_item.instance_setting.filters[0].values = valuesReset
    }
  },
};
</script>
<style scoped></style>
