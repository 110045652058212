  <template>
  <div style="height:100%" v-if="getGlobalProperty">
    <div
      style="height:100%"
      id="MainDashboardGridLayout"
      ref="MainDashboardGridLayout"
      v-if="getGlobalProperty.global_setting"
      @click="containerClicked(null)"
    >
      <grid-layout
        id="dashboard"
        ref="gridlayout"
        :style="
          'height:100%;  overflow-y: auto; touch-action: none; overflow-x: hidden; background:' +
          getGlobalProperty.global_setting.background_color
        "
        :layout="getDashboardWidgets"
        :col-num="12"
        :row-height="12"
        :is-draggable="!getGlobalProperty.global_setting.is_locked"
        :is-resizable="true"
        :responsive="false"
        :use-css-transforms="true"
        :preventCollision="getGlobalProperty.global_setting.is_moveable"
        :verticalCompact="getGlobalProperty.global_setting.snap_to_grid"
        :margin="[5,5]"
      >
        <grid-item
          v-for="(item, index) in getDashboardWidgets"
          :key="item.i"
          @click="widgetEditClicked(item)"            
          :style="'background:' + item.local_setting.foreground_color"
          :is-draggable="!item.local_setting.is_locked"
          :static="getEditMode ? false : true"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :id="item._id"
          :min-w="item.minW"
          :min-h="item.minH"
          ref="items"
        >
          <GridLayoutComponent 
            v-model:grid_item="getDashboardWidgets[index]" 
            @lock="lockItem(item)"
            @remove="widgetRemoveClicked(item)"
          />
        </grid-item>
      </grid-layout>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      location="bottom"
      absolute
      color="primary"
    >
      <div class="text-center">
        {{ snackbarMessage }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { GridLayout, GridItem } from "vue3-grid-layout-next";
import GridLayoutComponent from "@/components/GridLayoutComponent.vue";
import bus from "../plugins/bus";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";

let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 1, h: 1, i: null };

export default {
  components: {
    GridLayout,
    GridItem,
    GridLayoutComponent,
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getEditMode,
      getGlobalProperty,
      getSelectedNavigationTab,
      getSelectedPageStateId,
      getSelectedWidget,
      getWidgets,
      getwidgetIDsToUpdate,
      getWidgetsToDelete,
      getDashboardWidgets
    } = storeToRefs(notebookPropsStore)
    const {
      setWidgets,
      setWidgetsToDelete,
      setwidgetIDsToUpdate,
      setSelectedWidget,
      addWidget,
    } = notebookPropsStore
    const {
      updateColorByData,
      updateFilterByProperties,
      addFilter
    } = dataGraphicsStore
    return {notebookPropsStore, 
      getEditMode, getSelectedNavigationTab, getSelectedWidget, getwidgetIDsToUpdate,
      getGlobalProperty, getWidgets, getSelectedPageStateId, getWidgetsToDelete,
      setWidgets, setWidgetsToDelete, setwidgetIDsToUpdate, updateColorByData, 
      addFilter, updateFilterByProperties, setSelectedWidget, addWidget, getDashboardWidgets
    }
  },
  data() {
    return {
      color: "error", // error color is red in themes
      localWidgets: [],
      tempWidgets:[],
      foreground_color: "",
      clickOnWidget: false,
      //widgetIDsToUpdate: [],
      saveStateDisabledLoading: false,
      snackbarMessage: "",
      timeout: "2000",
      snackbar: false,
      tempWidgetCreated: false,
      lastDragIterationCompleted: false,
    };
  },
  computed: {},
  async created() {
    this.registerEventListeners();
    await this.updateColorByData();
  },
  async mounted() {
    //register event for 'mouse over canvas. '
    document.addEventListener(
      "dragover",
      function (e) {
        mouseXY.x = e.clientX;
        mouseXY.y = e.clientY;
      },
      false
    );
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  methods: {
    registerEventListeners(){
      bus.on("widget-selection-drag", (widgetName) => {
        this.drag(widgetName);
      });
      bus.on("widget-selection-dragend", (widgetName) => {
        this.dragend(widgetName);
      });
    },
    removeEventListeners(){
      bus.off("widget-selection-drag")
      bus.off("widget-selection-dragend")
    },
    async triggerLayoutPage(){
      await this.updateColorByData();
    },
    dragEvent(widgetName) {
      this.drag(widgetName);
    },
    dropEvent(widgetName) {
      this.dragend(widgetName);
    },
    containerClicked(item) {
      if (this.clickOnWidget) {
        this.clickOnWidget = false;
        //remove selectedwidget if no longer in widgets
        if (this.getSelectedWidget){
          if (!this.getWidgets.map(w=>w._id).includes(this.getSelectedWidget._id)){
            this.setSelectedWidget(null)
          }
        }
      } else {
        this.notebookPropsStore.$patch({selectedWidget: item})
        if (this.getSelectedNavigationTab == 'modelsOrDrawings'){
          this.notebookPropsStore.$patch({selectedNavigationTab:'globalProperties'})
        }
      }
    },
    widgetEditClicked(selectedWidget) {
      let widgetObject = selectedWidget;
      this.notebookPropsStore.$patch({selectedWidget: widgetObject})

      //if widget is not in ToUpdate array, add it. 
      if (!this.getwidgetIDsToUpdate.includes(widgetObject._id)){
        let existingWidgetsCopy = JSON.parse(JSON.stringify(this.getwidgetIDsToUpdate))
        existingWidgetsCopy.push(selectedWidget._id)
        this.setwidgetIDsToUpdate(existingWidgetsCopy)
      }

      if (this.getSelectedNavigationTab == 'modelsOrDrawings' && 
        !(this.getSelectedWidget.content == 'viewer2d' ||this.getSelectedWidget.content == 'viewer3d')){
        this.notebookPropsStore.$patch({selectedNavigationTab:'globalProperties'})
      }
      else if (this.getSelectedNavigationTab == 'layouts' && 
      (this.getSelectedWidget.content == 'viewer2d' ||this.getSelectedWidget.content == 'viewer3d')){
        this.notebookPropsStore.$patch({selectedNavigationTab:'globalProperties'})
      }
      this.clickOnWidget = true;
    },
    lockItem(widget) {
      //Make lock button widget the current selected widget. 
      this.notebookPropsStore.$patch({selectedWidget: widget})

      widget.local_setting.is_locked = !widget.local_setting.is_locked

      //If any widgets are now unlocked, make global lock false. 
      this.getGlobalProperty.global_setting.is_locked 
        = this.getDashboardWidgets.filter((e) => e.local_setting.is_locked == false).length ? false : true;
    },
    async widgetRemoveClicked(item) {

      if (item.content.includes('filter')){
        const filterPromises = item.instance_setting.filters.map(filter => {
          return this.addFilter({ widget: item.i, filter: filter }, true);
        });

        const results = await Promise.all(filterPromises);
        let updateGlobalFilters = results.includes(true);

        if (updateGlobalFilters) await this.updateFilterByProperties();
        item.instance_setting.filters = [];
      }

      //Get ID widget in store's widgets
      const index = this.getWidgets.map((it) => it.i).indexOf(item.i);

      if (!this.getWidgetsToDelete.map(w => w._id).includes(item._id)){
        let widgetsToDeleteCopy = JSON.parse(JSON.stringify(this.getWidgetsToDelete))
        widgetsToDeleteCopy.push(item)
        this.setWidgetsToDelete(widgetsToDeleteCopy)
      }

      //Submit widget for deleting at store. 
      let widgetsCopy = JSON.parse(JSON.stringify(this.getWidgets))
      widgetsCopy.splice(index, 1)
      this.setWidgets(widgetsCopy)

    },
    /** 
     * This method is called on the bus 'widget-selection-drag'
     * and registers a 'half-widget' to the grid layout for
     * positional previewing as well as adjusts its position 
     * properties as it is draged around the grid-layout space. 
     */
    drag: function (widgetName) {
      this.lastDragIterationCompleted = false;
      //Get selected widget details
      let selectedWidget = WidgetsCollection.ui_element_widgets.find((widget) => widget.name == widgetName)

      //Check if mouse is over grid.
      let mouseInGrid = this.isMouseOverDashbaord(); 

      //If Drag event is over the grid, 
      //And the temp widget is not yet on the dashboard,
      //Then add temp widget to localwidgets.
      if (
        mouseInGrid === true &&
        (this.getDashboardWidgets.findIndex(item => item.i === "drop") == -1))
      {
        let newWidget = this.createTempWidget(selectedWidget);
        this.addWidget(newWidget)
        this.tempWidgetCreated = true;
      }
      //Get temp widget index in localWidgets. 
      let index = this.getDashboardWidgets.findIndex((item) => item.i == "drop");

      //If temp widget exists in local widgets. 
      if (index !== -1) {

        if (this.$refs.items) {
          try {
            this.$refs.items[index][
              this.getDashboardWidgets.length
            ].$refs.items[index].style.display = "none";
          } catch {}

          //Update temp widget dom element's location.
          var el
          let new_pos = {};
          
          //Identify grid parint Div.
          let parentRect = document.getElementById("MainDashboardGridLayout").getBoundingClientRect();

          if (this.$refs.items[index]){
            el = this.$refs.items[index];
            el.dragging = {
              top: mouseXY.y - parentRect.top,
              left: mouseXY.x - parentRect.left,
            };

            //Calculate position based on mouse and 
            //grid layout coordinates. 
            new_pos = el.calcXY(
              mouseXY.y - parentRect.top,
              mouseXY.x - parentRect.left
            )

            //If mouse in grid, 
            //then update drag event 
            //and position details.
            if (mouseInGrid === true) {
              this.$refs.gridlayout.dragEvent(
                "dragstart",
                "drop",
                new_pos.x,
                new_pos.y,
                selectedWidget.h,
                selectedWidget.w
              );
              DragPos.i = String(index);
              DragPos.x = this.getDashboardWidgets[index].x;
              DragPos.y = this.getDashboardWidgets[index].y;
            }

            //If mouse is not in grid, 
            //then remove the temp widget from
            //the local widget collection. 
            if (mouseInGrid === false) {
              this.$refs.gridlayout.dragEvent(
                "dragend",
                "drop",
                new_pos.x,
                new_pos.y,
                selectedWidget.h,
                selectedWidget.w
              );
              //this.tempWidgets = [];
              let widgetInDashboard = this.getWidgets.filter((obj) => obj.i !== "drop");
              this.setWidgets(widgetInDashboard)
            }
          }
        }
      }
    },
    /**
     * This method is called on the dragend bus event 
     * when a new widget has been droped on the canvas.
     * This will commit this new widget to the store. 
     * @param string type - widget type 
     */
    dragend: async function (widgetType) {
      let mouseInGrid = this.isMouseOverDashbaord();
      if (mouseInGrid === true) {
        if (this.$refs.gridlayout != null){
          this.$refs.gridlayout.dragEvent(
            "dragend",
            "drop",
            DragPos.x,
            DragPos.y,
            1,
            1
          );
        }
        //Remove Temp Widget
        let widgetInDashboard = this.getDashboardWidgets.filter((obj) => obj.i !== "drop");
        this.setWidgets(widgetInDashboard)

        //Add Full Widget.
        let draggedWidget = this.createFullWidget(widgetType);
        this.addWidget(draggedWidget)

        try {
          this.$refs.items[
            this.localWidgets.length
          ].$refs.items[index].style.display = "block";
        } catch {}
      }
    },
    createTempWidget(selectedWidget){
      return {
          x: (this.localWidgets.length * 2) % (this.colNum || 12),
          y: this.localWidgets.length + (this.colNum || 12),
          w: selectedWidget.w,
          h: selectedWidget.h,
          local_setting: {
            foreground_color: this.getGlobalProperty.global_setting.foreground_color,
            is_locked: false,
          },
          instance_setting:{
            data:null,
            isPanelWidget:false,
          },
          i: "drop",
          page_state:this.getSelectedPageStateId,
        }
    },
    createFullWidget(widgetType){
      let draggedWidget = {
        ...WidgetsCollection.genericNewWidget(), 
        ...WidgetsCollection.getWidgetDetails(widgetType)
      }

      draggedWidget.x = DragPos.x
      draggedWidget.y = DragPos.y
      draggedWidget.i = new Date().getTime();
      draggedWidget.local_setting.is_locked = this.getGlobalProperty.global_setting.is_locked;
      draggedWidget.local_setting.foreground_color = this.getGlobalProperty.global_setting.foreground_color;
      draggedWidget.new = true;
      draggedWidget.page_state = this.getSelectedPageStateId;
      return draggedWidget;
    },
    isMouseOverDashbaord(){
      let parentRect = document.getElementById("MainDashboardGridLayout").getBoundingClientRect();
      let mouseInGrid = false;
      if (
        mouseXY.x > parentRect.left &&
        mouseXY.x < parentRect.right &&
        mouseXY.y > parentRect.top &&
        mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      return mouseInGrid;
    }
  },
};
</script>

<style scoped>
:deep(.vue-grid-item.vue-grid-placeholder) {
  background: rgb(var(--v-theme-darkGrey));
}

.vue-grid-layout {
  background: rgb(var(--v-theme-background));
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: white;
  border: 0px solid rgb(var(--v-theme-darkSlate));
  border-radius: 7px;
}
.vue-grid-item .resizing {
  opacity: 0.9;
  z-index: 20;
}
.vue-grid-item .static {
  background: rgb(var(--v-theme-background));
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
:deep(.vue-resizable-handle) {
  background: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 5 5' style='enable-background:new 0 0 5 5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bstroke:%23000000;stroke-width:0.1;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0-1'/%3E%3Cpolygon points='1,3 3,3 3,1 '/%3E%3C/svg%3E%0A")
    no-repeat;
  background-position: bottom right;
  z-index: 20;
  cursor: nwsw-resize;
  width: 30px; /* Increase the width */
  height: 30px; /* Increase the height */
  margin: -8px; /* Apply negative margin to shift the handle */
}

.layoutJSON {
  background: rgb(var(--v-theme-background));
  border: 1px solid rgb(var(--v-theme-darkSlate));
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
</style>
