<template>
      <v-menu 
        activator="parent"  
        attach="parent"
        open-on-hover 
        :close-on-content-click="false"
        offset-y
      >
      <v-card>
        <div class="ma-2 text" v-if="!showLayersDropdown">
            <small>No Layer Attribute Selected</small>
        </div>

        <v-list 
          divider="true" 
          density="compact"
        >
          <v-list-subheader>LAYERS</v-list-subheader>
          <v-list-item 
            v-for="(item, index) in layers" 
            :key="index" 
            density="compact"
            class="px-0 ma-1"
          >
            <v-row align="center" dense v-if="layers[0].elemIds.length > 0">
              <v-btn
                icon
                density="compact"
                variant="text"
                @click="
                  item.isVisible = !item.isVisible;
                  layersUpdated(index);
                "
              >
                <v-icon size="x-small" v-if="item.isVisible">fa-solid fa-eye</v-icon>
                <v-icon size="x-small" v-else>fa-solid fa-eye-slash</v-icon>
                <!-- <v-tooltip location="bottom" activator="parent">Hide</v-tooltip> -->
              </v-btn>

                <v-btn
                  icon
                  density="compact"
                  variant="text"
                  @click="
                    item.isLocked = !item.isLocked;
                    layersLocked(index);
                  "
                >
                  <v-icon size="x-small" v-if="item.isLocked">fa-solid fa-fw fa-lock</v-icon>
                  <v-icon size="x-small" v-else>fa-solid fa-lock-open</v-icon>
                  <!-- <v-tooltip location="bottom" activator="parent">Lock</v-tooltip> -->
                </v-btn>
              <v-list-item-title class="pl-2">{{ item.name }}</v-list-item-title>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>        
</template>

<script>
import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

export default {
  props: ["layers"],
  data() {
    return {
      closeOnContentClick: false,
    };
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getEditMode,
    } = storeToRefs(notebookPropsStore)
    const {
    } = notebookPropsStore
    return {getEditMode}
  },
  created(){
    //console.log(this.layers)
  },
  computed:{
    showLayersDropdown(){
      let show = false
      this.layers.forEach(layer => {
        if (layer.elemIds.length > 0) show = true
      });
      return show
    }
  },
  methods: {
    layersUpdated(index) {
      // console.log("layers settings is updated", index);
      this.$emit("layersUpdated", { layers: this.layers, index: index });
    },
    layersLocked(index) {
      // console.log("layers settings is updated", index);
      this.$emit("layersLocked", { layers: this.layers, index: index });
    },
  },
};
</script>

<style scoped>
.v-list {
  height: 200px;
  overflow-y: auto;
}
.v-btn:deep(.v-btn__content){
  max-width: 100% !important;
  justify-content:  start !important;
}
</style>