<template>
  <div class="widgetPadding" style="height: 100%; width: 100%; overflow-y: scroll;">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon= messages.widgetEmpty.icon></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>
    
    <!-- Filter Status -->
    <StatusFilter 
    :switchValueResult="grid_item.instance_setting.data.switchValueResult"
    :showSearch="grid_item.instance_setting.data.showSearch"
    :showRange="grid_item.instance_setting.data.showRange"
    :showValue="grid_item.instance_setting.data.showValue"
    ></StatusFilter>
  </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import StatusFilter from "../../components/ui/StatusFilter.vue";

import * as icons from "@/utilities/EllipseIcons.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

import { useAssetsStore } from "@/store/AssetsStore.js";
import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle, StatusFilter },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
    } = storeToRefs(notebookPropsStore)
    const {
      getAllFilterRules,
      renderFilters,
      getFilterByData,
      getAttrData,
      getAttrHeaders,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    return {
      dataGraphicsStore,
      notebookPropsStore,
      getEditMode,
      getDatasetLoading,
      getAllFilterRules,
      renderFilters,
      getFilterByData,
      getAttrData,
      getAttrHeaders,
      getSelectedPageDatasets,
      icons,
      lexicon,
      messages,
    }
  },  
  data(){
    return{
      switchValueResult:true,
      showSearch:true,
      showRange:true,
      showValue:true,
    }
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  created() {
    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();
  },
  methods: {
    verifyInstanceSettings() {

      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!('switchValueResult' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.switchValueResult = this.switchValueResult;
      if (!('showSearch' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.showSearch = this.showSearch;
      if (!('showRange' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.showRange = this.showRange;
      if (!('showValue' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.showValue = this.showValue;
    },
 },
};
</script>
