import Vue from "vue";
import { createRouter, createWebHistory } from 'vue-router'

import guards from "@/router/guards";

import Home from "@/pages/Home.vue";
import NoteBooks from "@/pages/NoteBooks.vue";
import Templates from "@/pages/Templates.vue";
import Layouts from "@/pages/Layouts.vue";
import Notebook from "@/pages/Notebook.vue";
import SidePanel from "@/components/SidePanel.vue";
import Dashboard from "@/pages/Dashboard.vue";
import ManageUsers from "@/pages/ManageUsers.vue";
import Page404 from "@/pages/Page404.vue";
import Page401 from "@/pages/Page401.vue";
import Account from "@/pages/Account.vue";
import Settings from "@/pages/Settings.vue";
import PluginDownloads from "@/pages/PluginDownloads.vue";
import About from "@/pages/About.vue";
import { authGuard, adminGuard, redirectToDashboard  } from "@/auth/authGuard";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: redirectToDashboard,
    meta: {
      title: (route) => "Ellipse: Home",
      guards: [guards.redirectToDashboard],
    },
    props: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
    meta: {
      title: (route) => "Ellipse: Dashboard",
      // guards: [guards.authGuard],
    },
    redirect: '/dashboard',
    props: true,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: NoteBooks,
      },
      {
        path: "noteBooks",
        name: "NoteBooks",
        component: NoteBooks,
      },
      {
        path: "templates",
        name: "Templates",
        component: Templates,
      },
      {
        path: "layouts",
        name: "Layouts",
        component: Layouts,
      },
    ],
  },
  {
    path: "/notebook/:id",
    name: "Notebook",
    components: {
      default:Notebook,
      panel:SidePanel,
    },
    beforeEnter: authGuard,
    meta: {
      title: (route) => "Ellipse: Notebook",
      // guards: [guards.authGuard],
    },
    props: true,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard
      },
      {
        path: "page/:pageId",
        name: "Page",
        component: Dashboard
      },
      {
        path: "visitor/:pageId",
        name: "Visitor",
        component: Dashboard
      },
    ]
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: (route) => "Ellipse: About",
    },
    props: true,
  },
  {
    path: "/pluginDownloads",
    name: "PluginDownloads",
    component: PluginDownloads,
    beforeEnter: authGuard,
    meta: {
      title: (route) => "Ellipse: PluginDownloads",
      // guards: [guards.authGuard],
    },
    props: true,
  },
  {
    path: "/manageUsers",
    name: "ManageUsers",
    component: ManageUsers,
    beforeEnter: adminGuard,
    meta: {
      title: (route) => "Ellipse: ManageUsers",
      // guards: [guards.adminGuard],
    },
    props: true,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    beforeEnter: authGuard,
    meta: {
      title: (route) => "Ellipse: Account",
      // guards: [guards.authGuard],
    },
    props: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: authGuard,
    meta: {
      title: (route) => "Ellipse: Settings",
      // guards: [guards.authGuard],
    },
    props: true,
  },
  // {
  //   // catch all 404
  //   path: "/404",
  //   component: Page404,
  //   meta: {
  //     title: (route) => "Ellipse: Unknown",
  //   },
  // },
  {
    // not authorized
    path: "/401",
    component: Page401,
    name: "401",
    meta: {
      title: (route) => "Ellipse: NotAuthorized",
    },
  },

  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: Page404,
}
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title(to);
  }
  if (!to.meta.guards || to.meta.guards.length < 1) return next();
  return guards.pipeline(to, from, next, to.meta.guards);
});

export default router;
