<template>
  <div class="widgetPadding pt-1 mt-0" style="height:100;">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading" ></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

    <v-layout
      class="flexColumn"
      v-if="tableData && overAllTableHeaders.length > 0"
    >
      <v-data-table 
        v-if="overAllTableHeaders.length > 0"
        v-model:page="page"
        class=" py-0 my-0"
        :height="`${grid_item.h * 12 + (grid_item.h - 1) * 5 - 35}px`"
        :headers="overAllTableHeaders"
        :items-per-page="itemsPerPage"
        :items="tableData"
        :loading="tableLoading"
        hide-default-footer
        fixed-header
        no-data-text="No Data"
        density="compact"
      >
        <template v-slot:item="{ item }">
          <tr
            :style="
                (selectedMap[item._id] && showHighlights)?
                `background: ${getGlobalProperty.global_setting.selection_color}; font-weight: bold;`
              : (highlightedMap[item._id] && showHighlights) ? 
                `background: ${getGlobalProperty.global_setting.highlight_color};`
              : ''
            "
            class="truncate"
          >
            <td
              class="truncate"
              style="width: auto; height: 26px; font-size: 12px; font-weight: 400; background: transparent"
              v-for="(attrKey, attrIdx) in overAllTableHeaders"
              :key="attrIdx"
            >
              <!-- Display file icon and name -->
              <span v-if="attrKey.key === 'name'">
                <v-icon> mdi:{{ getFileIcon(item.type) }}</v-icon>
                {{ formatValue(item[attrKey.key]) }}
              </span>

              <!-- If size, format to b, kb, mb. -->
              <span v-else-if="attrKey.key === 'size'">
                {{ formatSize(item[attrKey.key]) }}
              </span>

              <!-- Display file type, etc. -->
              <span v-else>
                {{ formatValue(item[attrKey.key]) }}
              </span>
            </td>
            <!-- Open File button -->
            <td class="truncate" style="width: auto; height: 26px; font-size: 12px; font-weight: 400; background: transparent">
              <v-btn 
                variant="text"
                icon
                size="x-small" 
                @click="openFile(item)"
              >
                <v-icon>mdi:mdi-open-in-new</v-icon>
              </v-btn>
            </td>
          </tr>
        </template >
        <template v-slot:bottom></template>
      </v-data-table>
      
      <!-- Footer with pagination. -->
      <div class="text-center d-flex" style="height:25px !important">
        <div style="width:70px" class="pl-2">
          <v-select
            :model-value="itemsPerPage"
            class="pa-2"
            hide-details
            density="compact"
            :items="itemsPerPageOptions"
            @update:model-value="itemsPerPage = parseInt($event, 10)">
          </v-select>
        </div>
        <div class="d-flex align-center pl-3">
          <small>{{page*itemsPerPage-itemsPerPage+1}}-{{page * itemsPerPage}} of {{tableData.length}}</small>
        </div>
        <v-pagination 
          class="d-flex align-center"
          v-model="page" 
          :length="pageCount" 
          density="compact"
          prev-icon= icons.arrowLeft
          next-icon= icons.arrowRight
        >
          <template v-slot:item></template>
        </v-pagination>
      </div>

    </v-layout>
  </div>
</template>
<script>
import {RGBToHex, setDigits, isDark} from "@/utilities";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon,WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
      getDatasetLoading
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getFilterByData,
      getAttrData,
      getAttrHeaderNames,
      lookupAttrDataElement
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets,
      getSelectedPageDataset,
      getSelectedPageFileAttachments,
      getFilteredFileAttachments,
      fetchSignedUrlForFile,
    } = storeToRefs(assetsStore)
    const {
      setHighlightedElements,
      removeFromSelectedElements,
      addToSelectedElements,
      addRangeToSelectedElements
    } = notebookPropsStore
    return {notebookPropsStore, dataGraphicsStore, assetsStore, getSelectedPageFileAttachments,getFilteredFileAttachments, fetchSignedUrlForFile,
    getHighlightedElements, getGlobalProperty, getSelectedElements, getSelectedPageDataset, getDatasetLoading, lookupAttrDataElement,
    getSelectedPageDatasets, getEditMode, getColorByData, getFilterByData, getAttrData, getAttrHeaderNames,
    setHighlightedElements, removeFromSelectedElements, addToSelectedElements, addRangeToSelectedElements,
    icons, 
    }
  },
  data() {
    return {
      overAllTableHeaders: [],
      showHighlights: true,
      showFilters: true,
      isColorBy: false,
      colorBy: null,
      textBy: null,
      page: 1,
      itemsPerPage: 50,
      itemsPerPageOptions: [50,100,250,500,1000],
      tableLoading:false,
      multiSelectStartIndex:-1,
      hoveredArrowIndex: -1,
      selectionRange: [],
      file_headers: [
      'name',
      'type',
      'size',
      //'dateUploaded',
      //'uploadedBy'
    ]
    };
  },
  computed: {
    tableData() {
      if(this.showFilters){
        return this.getFilteredFileAttachments;
      } else {
        return this.getSelectedPageFileAttachments;
      }
    },
    isSelected() {
      return (item) => {
        let selected = false;
        this.getSelectedElements.forEach(selectedElement => { 
          let element = this.getAttrData.find(element => element.ellipseId == selectedElement)
          if (element._files && element._files.includes(item._id)) {
            selected = true;
          }
        });
        return selected;
      };
    },
    isHighlighted() {
      return (item) => {
        let highlighted = false;
        this.getHighlightedElements.forEach((highlightedElement) => {
          let element = this.getAttrData.find(element => element.ellipseId == highlightedElement)
          if (element._files && element._files.includes(item._id)) {
            highlighted = true;
          }
        });
        return highlighted;
      };
    },
    selectedMap() {
      const map = {};
      this.getSelectedElements.forEach(selectedElement => {
        let element = this.getAttrData.find(element => element.ellipseId == selectedElement);
        if (element && element._files) {
          element._files.forEach(fileId => {
            map[fileId] = true;
          });
        }
      });
      return map;
    },
    highlightedMap() {
      const map = {};
      this.getHighlightedElements.forEach(highlightedElement => {
        let element = this.getAttrData.find(element => element.ellipseId == highlightedElement);
        if (element && element._files) {
          element._files.forEach(fileId => {
            map[fileId] = true;
          });
        }
      });
      return map;
    },
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    totalDataItems(){
      return this.getSelectedPageFileAttachments.length;
    },
    totalTableItems(){
      return this.tableData.length;
    },
    pageCount(){
      return Math.ceil(this.totalTableItems / this.itemsPerPage)
    },
  },
  created() {
    this.verifyInstanceSettings();
  },
  async mounted() {
    this.verifyInstanceSettings();
    this.onNewAttributesReceived()
  },
  watch: {
    "grid_item.instance_setting.data.selectedAttrs": function () {
      this.updateHeaderEvent();
    },
    "grid_item.instance_setting.data.showHighlights": function () {
      this.showHighlights = this.grid_item.instance_setting.data.showHighlights;
    },
    "grid_item.instance_setting.data.showFilters": function () {
      this.showFilters = this.grid_item.instance_setting.data.showFilters;
    },
  },
  beforeUnmount() {
  },
  methods: {
    verifyInstanceSettings() {
      if(!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data={};
      if(!this.grid_item.instance_setting.data.selectedAttrs) this.grid_item.instance_setting.data.selectedAttrs = this.file_headers;
      if(!this.grid_item.instance_setting.data.showHighlights) this.grid_item.instance_setting.showHighlights=true;
      this.showHighlights = this.grid_item.instance_setting.data.showHighlights;
      if(!this.grid_item.instance_setting.data.showFilters) this.grid_item.instance_setting.showFilters=true;
      this.showFilters = this.grid_item.instance_setting.data.showFilters;
    },
    async datasetUpdated(){
      await this.onNewAttributesReceived();
    },
    updateHeaderEvent(args){
      let headers = this.grid_item.instance_setting.data.selectedAttrs;
      this.overAllTableHeaders = headers.map((e) => {
        return {
          title: e,
          key: e,
          align: "center",
          sortable: true,
        };
      });
    },
    updateOptions(updatedOptions){
      this.options = updatedOptions;
    },
    formatValue(data) {
        return setDigits(data);
    },
    getItemValue(item, key = 'ellipseId'){
      if(item){
        if(item['selectable']){
          if(item.selectable[key]){
            return item.selectable[key];
          }
        }
        if(item[key]) return item[key];
      }
    },
    formatSize(size) {
      if (size < 1024) {
        return size + ' B';
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      } else {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
      }
    },
    async onNewAttributesReceived() {
      if (this.grid_item.instance_setting.data !== null) {
        if (this.grid_item.instance_setting.data.selectedAttrs.length) {
          let presentHeaders = this.file_headers.filter((element) =>
            this.grid_item.instance_setting.data.selectedAttrs.includes(
              element
            )
          );
          this.overAllTableHeaders = presentHeaders.map((e) => {
            return {
              title: e,
              key: e,
              align: "center",
              sortable: true,
            };
          });
        }
      } 
      else {
        this.overAllTableHeaders =
          this.file_headers.map((e) => {
            return {
              title: e,
              key: e,
              align: "center",
              sortable: true,
            };
          });
      }
    },
    getFileIcon(fileType) {
      //console.log('fileType', fileType);
      switch (fileType) {
        case 'application/pdf':
          //note mdi-file-pdf was removed from mdi due to copyright
          return 'mdi-file-document';
        case 'application/jpeg':
          return 'mdi-file-image';
        case 'application/jpg':
          return 'mdi-file-image';
        case 'application/png':
          return 'mdi-file-image';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'mdi-file-word';
        case 'video/mp4':
          return 'mdi-file-video';
        default:
          return 'mdi-file';
      }
    },
    async openFile(file){
      //console.log('Opening file', file);
      let assetsStore = this.assetsStore;
      let fetchSignedUrlForFile = assetsStore.fetchSignedUrlForFile; 

      if (!file.signedUrl) {
        const signedUrl = await fetchSignedUrlForFile(file);
        file.signedUrl = signedUrl;
      }
      window.open(file.signedUrl, '_blank');
    },
  },
};
</script>
<style scoped>
  .truncate {
    max-width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-pagination :deep(.v-pagination__item){
    margin: 0px !important;
  }
  .v-input:deep( .v-field__input){
    width: 50px !important;
    margin-top: -8px !important;
    padding-top: 0px !important;
  }
  .v-input:deep(.v-field__append-inner){
    margin-top: -2px !important;
    margin-left: -10px !important;
    padding-top: 0px !important;
  }
  .v-input:deep(.v-field__outline) {
    position: relative !important;
  }
  .v-input:deep(.v-input__control) {
    height: 20px;
    margin-top: 0px !important
  }
  .v-input:deep(.v-select__selection-text){
    font-size: 12px !important;
    width: 50px
  }
  .v-input {
    padding: 0px !important
  }
  .v-table:deep(.v-data-table-header__content) {
    font-size: 12px !important;
    font-weight: 800 !important;
  }
</style>
