<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>
    
    <div v-if="getAttrData.length > 0 && grid_item.instance_setting.data" style="width:100%; height:97%;" class="ma-0" >

      <v-container class="px-0 py-0 d-flex">
      <h5 class="WidgetSubHeader mr-2">{{ grid_item.instance_setting.data.selectedItem }}</h5>
      </v-container>
      
      <!-- Attribute List. -->

      <v-layout
        v-if="!grid_item.instance_setting.data.viewChips"
        class="flexColumn pt-1"
        style="height: 100%;"
      >
        <v-data-table 
          class=" pt-0 mt-0"
          style="height: 100%; overflow-y: auto;"
          hide-default-footer
          fixed-header
          density="compact"
          :headers="[]"
          :items-per-page="itemsPerPage"
          :items="tableData"
          v-model:page="page"
          @current-items="handleCurrentItems"
          @mouseleave="removeHighlight"
        >
          <template v-slot:item="{ item }">
            <tr
              :style="
                getSelectedElements.includes(item.ellipseId)
                ? `background: ${getGlobalProperty.global_setting.selection_color}; font-weight: bold;`
                : verifyHighlight(item.ellipseId)
                ? `background: ${getGlobalProperty.global_setting.highlight_color};`
                : selectionRange.includes(item.ellipseId)
                ? `background: ${getGlobalProperty.global_setting.highlight_color};`
                : isColorBy
                ? `background: ${colorBy[item.ellipseId]}; color: ${textBy[item.ellipseId]};`
                : ''
              "
              class="truncate"
              @click.exact="clickEvent(item.ellipseId)"
              @click.ctrl="ctrlClickEvent(item.ellipseId)"
              @click.alt="altClickEvent(item)"
              @mouseover="hoverEvent(item, $event)"
            >
              <td
                class="truncate"
                style="width: auto; height: 26px; font-size: 12px; font-weight: 400; background: transparent"
                v-for="(attrKey, attrIdx) in [tableHeader]"
                :key="attrIdx"
              >
                {{ formatValue(item[attrKey.value]) }}
              </td>
            </tr>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
      
        <!-- Footer with pagenation. -->
        <div class="text-center d-flex" style="height:25px !important">
          <div style="width:70px" class="pl-2">
            <v-select
              :model-value="itemsPerPage"
              class="pa-2"
              hide-details
              density="compact"
              :items="itemsPerPageOptions"
              @update:model-value="itemsPerPage = parseInt($event, 10)">
            </v-select>
          </div>
          <div class="d-flex align-center pl-3">
            <small>{{page*itemsPerPage-itemsPerPage+1}}-{{page * itemsPerPage}} of {{totalTableItems}}</small>
          </div>
          <v-pagination 
            class="d-flex align-center"
            v-model="page" 
            :length="pageCount" 
            density="compact"
            prev-icon="fa-solid fa-caret-left"
            next-icon="fa-solid fa-caret-right"
          >
            <template v-slot:item></template>
          </v-pagination>
        </div> 
      </v-layout>

      <v-layout v-else style="height: 99%; overflow-y: auto">
        <v-infinite-scroll :items="dataChunk" @load="loadMoreData" direction="vertical" >
          <div>
            <template v-for="(data, index) in dataChunk" :key="index" >
              <v-chip
                size="small" 
                label
                class="elevation-1 pa-1 ml-0 mr-1 mt-0 mb-1"
                @click.exact="clickEvent(data.ellipseId)"
                @click.ctrl="ctrlClickEvent(data.ellipseId)"
                @click.alt="altClickEvent(data)"
                @mouseover="hoverEvent(data, $event)"
                :style="
                  getSelectedElements.includes(data.ellipseId)
                  ? `background: ${getGlobalProperty.global_setting.selection_color}; font-weight: bold;`
                  : verifyHighlight(data.ellipseId)
                  ? `background: ${getGlobalProperty.global_setting.highlight_color};`
                  : selectionRange.includes(data.ellipseId)
                  ? `background: ${getGlobalProperty.global_setting.highlight_color};`
                  : isColorBy
                  ? `background: ${colorBy[data.ellipseId]}; color: ${textBy[data.ellipseId]};`
                  : ''
                  "
              >
                {{ formatValue(data[grid_item.instance_setting.data.selectedItem]) }} 
              </v-chip>
            </template>
          </div>
          <template v-slot:empty>
          </template>
        </v-infinite-scroll>
      </v-layout>
    </div>
  </div>
</template>

<script>
import {RGBToHex, setDigits, isDark} from "@/utilities";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue"
import WidgetTitle from "../../components/ui/WidgetTitle.vue"

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

export default {
  components: {Spinner, NoDataWarning, WidgetIcon, WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
      getDatasetLoading
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getFilterByData,
      getAttrData
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      setHighlightedElements,
      removeFromSelectedElements,
      addToSelectedElements,
      addRangeToSelectedElements
    } = notebookPropsStore
    return {dataGraphicsStore, notebookPropsStore,
      getEditMode, getHighlightedElements, getColorByData, getFilterByData, getAttrData, 
     getSelectedElements, getGlobalProperty, getSelectedPageDatasets, getDatasetLoading,
    setHighlightedElements, addToSelectedElements, addRangeToSelectedElements, removeFromSelectedElements,
    }
  },
  data(){
    return{
      selectedItem: "None",
      filterBy:[],
      tableData: [],
      isColorBy: false,
      colorBy: null,
      datasetSwitchingLoading:false,
      viewChips:false,
      itemsPerPage: 50,
      page: 1,
      itemsPerPageOptions: [50,100,250,500,1000,],
      tableLoading:false,
      tableCurrentItems: [],
      multiSelectStartIndex:-1,
      hoveredArrowIndex: -1,
      selectionRange: [],
      dataChunk: [],
      itemsPerChunk: 50
    }
  },
  watch: {
    "grid_item.instance_setting.data.selectedItem": function() {
      this.setListValue()
    },
	},
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    tableHeader(){
      return {
        text: this.grid_item.instance_setting.data.selectedItem,
        value: this.grid_item.instance_setting.data.selectedItem,
        align: "center",
      }
    },
    totalTableItems(){
      return this.tableData.length;
    },
    pageCount(){
      return Math.ceil(this.totalTableItems / this.itemsPerPage)
    },
  },
  created(){
    this.verifyInstanceSettings();
  }, 
  mounted() {
    this.verifyInstanceSettings();
    if (this.getColorByData.graphicData){
      this.onNewColorByUpdated();
    }
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByData') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
        if (name === 'removeAllFilters'){
          this.tableData = this.getFilteredData()
        }
      })
    }) 
  },
  methods: {
    verifyInstanceSettings() {
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      if (!this.grid_item.instance_setting.data.selectedItem) this.grid_item.instance_setting.data.selectedItem = this.selectedItem;
      if (!this.grid_item.instance_setting.data.isColorBy) this.grid_item.instance_setting.data.isColorBy = this.isColorBy;
      if (!this.grid_item.instance_setting.data.colorBy) this.grid_item.instance_setting.data.colorBy = this.colorBy;
      if (!this.grid_item.instance_setting.data.viewChips) this.grid_item.instance_setting.data.viewChips = this.viewChips;
      if (!this.grid_item._id) {
        
      }
      this.onNewFilterByUpdated();
      this.tableData = this.getFilteredData();
      this.dataChunk = this.tableData.slice(0, this.itemsPerChunk)
    },
    setDigits(value){
      return setDigits(value);
    },
    setListValue(){
      this.selectedItem = this.grid_item.instance_setting.data.selectedItem;
      this.verifyInstanceSettings();
      if (this.getColorByData.graphicData){
        this.onNewColorByUpdated;
      }
    },
    handleCurrentItems(val){
      this.tableCurrentItems = val;
    },
    clickEvent(ellipseId) {
      this.notebookPropsStore.$patch({selectedElements: [ellipseId]})
    },
    chipClickEvent(ellipseId) {
      if(this.getSelectedElements.includes(ellipseId)){
        this.removeFromSelectedElements(ellipseId)
      }else{
        this.addToSelectedElements(ellipseId)
      }
    },
    verifyHighlight(id){
      return this.getHighlightedElements.includes(id);
    }, 
    async onNewFilterByUpdated() {
      this.tableData = this.getFilterByData.graphicData;
    },
    getFilteredData(){
      let dataToFilter = this.getFilterByData.graphicData ? this.getFilterByData.graphicData : this.getAttrData;
      return dataToFilter.filter((data) => {
        let value = data[this.grid_item.instance_setting.data.selectedItem];
        if (value != '' && value != null && value != undefined && !this.filterBy.includes(data.ellipseId)) {
          return true;
        } 
      });
    },
    async loadMoreData({done}){
      if (this.dataChunk.length == this.tableData.length){
        done('empty')
        return 
      }
      const startIndex = this.dataChunk.length
      const endIndex = startIndex + this.itemsPerChunk
      const newChips = this.tableData.slice(startIndex, endIndex)
      this.dataChunk = this.dataChunk.concat(newChips)
      done('ok')
    },
    formatValue(data) {
      return setDigits(data);
    },
    ctrlClickEvent(ellipseId){
      if(this.getSelectedElements.includes(ellipseId)){
        this.removeFromSelectedElements(ellipseId)
      }else{
        this.addToSelectedElements(ellipseId)
      }
    },
    altClickEvent(item){
        //If First Alt-Click
        if (this.multiSelectStartIndex === -1) {
          this.multiSelectStartIndex = this.tableCurrentItems.indexOf(item);
          if(!this.getSelectedElements.includes(item.ellipseId)){
            this.addToSelectedElements(item.ellipseId)
          }
        } 
        //If Second Alt-Click
        else {
          //Get Index range of current table items
          const start = Math.min(this.multiSelectStartIndex, this.tableCurrentItems.indexOf(item));
          const end = Math.max(this.multiSelectStartIndex, this.tableCurrentItems.indexOf(item));
          
          //Get Ellipse Ids of items in range
          let selectedEllipseIds = [];
          for (let i = start; i <= end; i++) {
            selectedEllipseIds.push(this.tableCurrentItems[i].ellipseId);
          }
          //Submit Ellipse Ids to store and reset multiSelectStartIndex
          this.addRangeToSelectedElements(selectedEllipseIds)
          this.multiSelectStartIndex = -1;
        }
    },
    hoverEvent(item, event) {
      if (event.altKey) {
        this.highlightItemsBetween(item);
      } else {
        this.selectionRange = [];
        this.multiSelectStartIndex = -1;
      }
      if (item.ellipseId){
        this.setHighlightedElements([item.ellipseId])
      }
    },
    highlightItemsBetween(currentItem) {
      if (this.multiSelectStartIndex === -1) return;
      const startIndex = Math.min(this.multiSelectStartIndex, this.tableCurrentItems.indexOf(currentItem));
      const endIndex = Math.max(this.multiSelectStartIndex, this.tableCurrentItems.indexOf(currentItem));
      this.selectionRange = [];
      for (let i = startIndex; i <= endIndex; i++) {
        this.selectionRange.push(this.tableCurrentItems[i].ellipseId);
      }
    },
    removeHighlight() {
      this.setHighlightedElements([])
    },
    updateOptions(updatedOptions){
      this.options = updatedOptions;
    },
    async onNewColorByUpdated() {
      this.isColorBy = false;
      this.colorBy = {};
      this.textBy = {};
      let opts = this.getColorByData.graphicData;
      if (!opts) return;

      // Iterate through the ids_inValidValue array and add them to the colorBy object with the default color
      opts.ids_inValidValue.forEach((id) => {
        this.colorBy[id] = '#ffffff'; 
        this.textBy[id] = '#000000'; 
      });

      for (let idx = 0; idx < opts.ids.length; idx++) {
        let rgbStr = opts.colors[idx];
        rgbStr = rgbStr.slice(4, rgbStr.length - 1);
        let rgbArr = rgbStr.split(",").map((s) => parseInt(s.trim()));
        let c = RGBToHex(...rgbArr);
        let ellId = opts.ids[idx];
        this.colorBy[ellId] = c;
        this.textBy[ellId] = isDark(opts.colors[idx]);
      }
      this.isColorBy = true;
    },
  },
};
</script>

<style scoped>
  .v-pagination :deep(.v-pagination__item){
    margin: 0px !important;
  }
  .v-input:deep( .v-field__input){
    width: 20px !important;
    margin-top: -8px !important;
    padding-top: 0px !important;
  }
  .v-input:deep(.v-field__append-inner){
    margin-top: -2px !important;
    margin-left: -10px !important;
    padding-top: 0px !important;
  }
  .v-input:deep(.v-field__outline) {
    position: relative !important;
  }
  .v-input:deep(.v-input__control) {
    height: 20px;
    margin-top: 0px !important
  }
  .v-input:deep(.v-select__selection-text){
    font-size: 12px !important;
    width: 20px
  }
  .v-input {
    padding: 0px !important
  }
  .v-text-field {
    font-size: 12px !important;
  }
  .selected-chip {
    background-color: rgb(var(--v-theme-darkSlate));
    color: white;
  }
</style>
