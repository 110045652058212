<template>
  <div>
    <div class="pt-0 pb-0" v-if="assetInfo.name === 'datasets'">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="10">
          <v-select 
          :model-value="getSelectedPageDataset" 
          :items="getSelectedPageDatasets" 
          :item-props="itemProps"
          density="compact" 
          variant="outlined" 
          return-object 
          @update:modelValue="updateDataset($event)">
        </v-select>
        </v-col>
        <v-col class="ma-0 pa-0" cols="2">
          <v-menu  offset-y>
          <template v-slot:activator="{ props}">
            <v-btn v-bind="props" size="small" icon variant="text">
              <v-icon >{{ icons.menu }}</v-icon></v-btn>
          </template>
          <v-list density="compact">
            <v-list-item @click="assetEditor(getSelectedPageDataset)">
              <template v-slot:prepend>
                <v-icon>{{ icons.rename }}</v-icon>
              </template>
              <v-list-item-title>{{ lexicon.rename }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadAsset(getSelectedPageDataset)">
              <template v-slot:prepend>
                <v-icon>{{ icons.download }}</v-icon>
              </template>
              <v-list-item-title>{{ lexicon.download }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="getNotebook.userPermissions.permissions >= 500" @click="clickOnDeleteDataset(getSelectedPageDataset)">
              <template v-slot:prepend>
                <v-icon>{{ icons.remove }}</v-icon>
              </template>
              <v-list-item-title>{{ lexicon.del }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-col>
      </v-row>
    </div>

    <!-- Drawing or Model Assets - depending on selected Tab -->
    <div v-else>
      <!-- List of Assets. -->
      <span v-for="asset in assets" :key="asset._id">
        
        <!-- Asset Row. -->
        <v-row class="align-center py-1" cols="12">
          <v-col class="d-flex align-center" cols="2">
            <v-btn
              style="width:20px !important"
              class="pa-0"
              size="small"
              elevation="0"
              color="white"
              :loading="loadingCheckbox[`${asset._id}`]"
            > 
            <template v-slot:loader>
              <v-progress-circular size="small" indeterminate color="primary"></v-progress-circular>
            </template>

            <v-tooltip location="top" activator="parent">
              <span v-if="getSelectedWidget">Toggle checkbox to add or remove asset into widget.</span>
              <span v-else>Check to add to Page. <br> The file will be available in all viewers.</span>
            </v-tooltip>
            <!-- Asset Checkbox states. -->
              <template v-if="getSelectedWidget && getSelectedNavigationTab == 'modelsOrDrawings'"
                ><v-checkbox
                  style="margin-top: -5px"
                  :disabled="!getSelectedWidget._id"
                  :model-value="checkAssetInWidget(asset)"
                  density="compact"
                  color="primary"
                  hide-details
                  @update:modelValue="insertRemoveAssetsInWidget(asset)"
                ></v-checkbox
              ></template>
              <template v-else>
                <v-checkbox
                  style="margin-top: -5px"
                  :model-value="checkAssetInPage(asset)" 
                  density="compact" 
                  hide-details 
                  color="primary"
                  @update:modelValue="insertRemoveAssetIdInPageState(asset)">
                </v-checkbox>
              </template>
            </v-btn>
          </v-col>

          <v-col cols="1">
            <v-dialog width="50%">
              <template v-slot:activator="{ props}">
                <v-btn v-bind="props" icon size="x-small" variant="plain">
                  <v-icon size="small">{{ icons.information }}</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5 bg-grey-lighten-2">
                  <v-icon size="x-small" color="darkGrey">{{ icons.information }}</v-icon>
                </v-card-title>

                <v-card-text>
                  <v-table density="compact">
                    <tbody>
                      <tr>
                        <td><b>Name</b></td>
                        <td class="text-truncate">{{ asset.nickname }}</td>
                      </tr>
                      <tr>
                        <td width="50px">File</td>
                        <td class="text-truncate">{{ asset.name }}</td>
                      </tr>
                      <tr>
                        <td>Created</td>
                        <td>{{ $filters.momentTimestamp(asset.createdAt) }}</td>
                      </tr>
                      <tr>
                        <td>Modified</td>
                        <td>{{ $filters.momentTimestamp(asset.updatedAt) }}</td>
                      </tr>
                    </tbody>
                  </v-table>
                </v-card-text>

                <v-divider></v-divider>

              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="7">
            <div
              class="text-truncate text-caption"
            >
              {{ asset.nickname }}
              <v-tooltip location="top" activator="parent">{{ asset.nickname }}</v-tooltip>
            </div>
          </v-col>
          
          <!-- Asset Options Menu.  -->
          <v-col class="d-flex justify-center" cols="2">  
            <v-menu offset->
              <!-- Button & Icon. -->
              <template v-slot:activator="{ props}">
                <v-btn v-bind="props"  size="x-small" icon variant="text">
                  <v-icon size="medium" class="mx-1 mr-2">{{ icons.menu }}</v-icon></v-btn>
              </template>

              <!-- Popup Menu -->
              <v-card>
                <v-list density="compact">
                  <v-list-item @click="assetEditor(asset)">
                    <template v-slot:prepend>
                      <v-icon>{{ icons.edit }}</v-icon>
                    </template>
                    <v-list-item-title>{{ lexicon.rename }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="downloadAsset(asset)">
                    <template v-slot:prepend>
                      <v-icon>{{ icons.download }}</v-icon>
                    </template>
                    <v-list-item-title>{{ lexicon.download }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="getNotebook.userPermissions.permissions >= 500" @click="clickOnDeleteDataset(asset)">
                    <template v-slot:prepend>
                      <v-icon>{{ icons.remove }}</v-icon>
                    </template>
                    <v-list-item-title>{{ lexicon.del }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

      </span>
    </div>
    <v-dialog v-model="editAssetDialog" width="500">
      <v-card flat>
        <v-card-title> Rename File </v-card-title>
          <v-text-field
            class="popUpTextField"
            variant="outlined"
            hide-details
            v-model="currentAsset.nickname"
            label="Name"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-white"
              medium
              color="darkGrey"
              @click="editAssetDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="!currentAsset.nickname"
              :loading="editAssetLoading"
              color="primary"
              @click="editAsset()"
            >
              Save
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <AreYourSurePopUp 
      :dialog="deleteDatasetDialog"
      @toggle="deleteDatasetDialog=!deleteDatasetDialog"
      :onClick="deleteAssetFromNotebook"
      :loading="deleteAssetLoading"
      >Are you sure you want to delete the File: {{assetInfo.name}}?
    </AreYourSurePopUp>

    <v-snackbar 
    v-model="snackbar" 
    :timeout="snackbarTimeout" 
      location="bottom" 
      absolute 
      :color="snackbarColor" 
      >
      <div class="text-center">
        {{ snackbarMessage }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import * as utils from "@/utilities";
import PillButton from "@/components/ui/PillButton.vue";
import ProgressLinear from "@/components/ui/ProgressLinear.vue";
import AreYourSurePopUp from "@/components/popUps/AreYouSure.vue";
import HelpButton from "@/components/ui/HelpButton.vue";

import * as assetInfo from "@/utilities/assetInfo.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";

export default {
  components: { PillButton, ProgressLinear, AreYourSurePopUp,HelpButton },
  props: {
    description: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: lexicon.dataset.icon
    },
    assetType: {
      type: String,
      default: "dataset"
    }
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedNavigationTab,
      getSelectedPage,
      getSelectedPageStateId,
      getSelectedWidget,
      getWidgets,
      getPagesStructuredData
    } = storeToRefs(notebookPropsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      setWidgets,
      setSelectedWidget
    } = notebookPropsStore
    const {
      getAllAssetsInNotebook,
      getSelectedPageDataset,
      getSelectedPageDatasets,
      getSelectedPageModels,
      getSelectedPageDrawings,
      getSelectedPageMaps,
      getSelectedPageImages,
    } = storeToRefs(assetsStore)
    const {
      setAllAssetsInNotebook,
      setSelectedPageDataset
    } = assetsStore
    return {
      notebookPropsStore,
      getSelectedNavigationTab,
      getSelectedWidget,
      getSelectedPageDatasets,
      getSelectedPageModels,
      getSelectedPageMaps,
      getSelectedPageDataset,
      getAllAssetsInNotebook,
      getSelectedPageDrawings,
      getWidgets,
      getSelectedPageImages,
      getSelectedPage,
      getSelectedPageStateId,
      getNotebook,
      getPagesStructuredData,
      setAllAssetsInNotebook,
      setSelectedPageDataset,
      setWidgets,
      setSelectedWidget,
      lexicon,
      icons
    }
  },
  data() {
    return {
      loadingCheckbox: {},
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      showFileUploadProgress: false,
      uploadPercentage: 0,
      snackbarTimeout: 2000,
      editAssetDialog:false,
      editAssetLoading:false,
      currentAsset:{},
      editAssetLoading:false,
      deleteAssetLoading:false,
      deleteDatasetDialog:false,
      deleteDrawingLoading:false,
      deleteDrawingDisabled:false,
      uploadingMsg:"",
      assetInfo: [],
      lexiconItem:lexicon.data,
      assets: [],
    };
  },
  created(){
    this.setComponentAssets()
  },
  mounted(){
    this.notebookPropsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setAllAssetsInNotebook') {
          this.setComponentAssets()
        }
      })
    })
  },
  computed: {},
  methods: {
      getDocumentationLink(assetName) {
        // Convert asset name to lower case for case-insensitive matching
        const lowerCaseAssetName = assetName.toLowerCase();
        // Check if the asset name exists in the uploadFilesDocumentation dictionary
        if (lowerCaseAssetName in this.uploadFilesDocumentation) {
            // Return the corresponding link
            return this.uploadFilesDocumentation[lowerCaseAssetName];
          } else {
            // Return a default link or handle the case when the asset name is not found
            return "#"; // Default link
          }
        },
      itemProps (item) {
        return {
          title: item.nickname,
          subtitle: new Date(item.updatedAt).toLocaleString(),
        }
      },
    checkAssetInPage(asset){
      return asset.pages.some((pageId) => pageId === this.getSelectedPage._id);
    },
    checkAssetInWidget(asset){
      return this.getSelectedWidget.instance_setting.data.assets.some(
        (w) => w._id === asset._id
      );
    },
    setComponentAssets(){
      switch (this.assetType){
        case 'data':
          this.assetInfo = assetInfo.dataAssetInfo
          this.lexiconItem = this.lexicon.data
          break;
        case '3D':
          this.assetInfo = assetInfo.modelAssetInfo
          this.assets = this.getAllAssetsInNotebook.models
          this.lexiconItem = this.lexicon.model
          break;
        case '2D':
          this.assetInfo = assetInfo.drawingAssetInfo
          this.assets = this.getAllAssetsInNotebook.drawings
          this.lexiconItem = this.lexicon.drawing
          break;
        case 'GIS':
          this.assetInfo = assetInfo.mapAssetInfo
          this.assets = this.getAllAssetsInNotebook.maps
          this.lexiconItem = this.lexicon.map
          break;
        case 'IMG':
          this.assetInfo = assetInfo.imageAssetInfo
          this.assets = this.getAllAssetsInNotebook.images
          this.lexiconItem = this.lexicon.image
          break;
        case '3D_widget':
          this.assetInfo = assetInfo.modelAssetInfo
          this.assets = this.getSelectedPageModels
          this.lexiconItem = this.lexicon.model
          break;
        case '2D_widget':
          this.assetInfo = assetInfo.drawingAssetInfo
          this.assets = this.getSelectedPageDrawings
          this.lexiconItem = this.lexicon.drawing
          break;
        case 'map_widget':
          this.assetInfo = assetInfo.mapAssetInfo
          this.assets = this.getSelectedPageMaps
          this.lexiconItem = this.lexicon.map
          break;
      }
    },
    assetEditor(asset){
      this.currentAsset = asset;
      this.editAssetDialog = true;
    },
    clickOnDeleteDataset(asset){
      this.currentAsset = asset;
      this.deleteDatasetDialog = true; 
    },
    async editAsset() {
      this.editAssetLoading = true;
      this.snackbarTimeout = 3000;
      this.snackbarColor = "success";
      this.snackbarMessage = "Edited Info";
      this.snackbar = true;

      await this.$auth.$api.post(this.assetInfo.editUrl+this.currentAsset._id+"/edit-info", this.currentAsset);
      this.editAssetLoading = false;
      this.editAssetDialog = false;
    },
    async updateDataset(event) {
      if (this.getSelectedPageDataset == null ||
        this.getSelectedPageDataset._id !== event._id){
        await this.setSelectedPageDataset(event);
      }
    },
    async downloadAsset(asset) {
      this.snackbarTimeout = 3000;
      this.snackbarColor = "success";
      this.snackbarMessage = "Downloading Asset";
      this.snackbar = true;

      let response = await this.$auth.$api.post(`/api/models/download-model`, {
        keys: [{ key: asset.s3_pointers[0] }],
      });
      let fileName = asset.name;
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          var downloadUrl = URL.createObjectURL(xhttp.response);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = downloadUrl;
          a.download = fileName;
          a.click();
        }
      };
      xhttp.open("GET", response.data.data[0].url, true);
      xhttp.responseType = "blob";
      xhttp.send();
    },
    async deleteAssetFromNotebook() {
      this.deleteAssetLoading = true;
      this.deleteDrawingDisabled = true;
      this.snackbarTimeout = 3000;
      this.snackbarColor = "warning";
      this.snackbarMessage = "Deleting Asset";
      this.snackbar = true;

      let assetId = this.currentAsset._id;

      //Find widgets that contain the given asset type
      let widgetsNeedsToUpdate = this.getRelevantWidgets()
      let widgets = []

      if (widgetsNeedsToUpdate){
        widgetsNeedsToUpdate.forEach(w => {
          let index = w.instance_setting.data.assets.map(a => a._id).indexOf(this.currentAsset._id);
          if (index != -1) {
            w.instance_setting.data.assets.splice(index, 1)
            let obj = {
              _id : w._id,
              assets: w.instance_setting.data.assets
            }
            widgets.push(obj);
          }
        });
      }

      //delete asset from notebook and widgets
      let route = `${this.assetInfo.deleteUrl}/${assetId}/delete`;
      await this.$auth.$api.delete(route, {data:{widgets:widgets}})
        .then(response => {
          //console.log("response", response);
        })
        .catch((e) => {
          console.log("Error", e);
        });

      await this.setAllAssetsInNotebook()

      if (this.assetType == 'data'){
        await this.setSelectedPageDataset(null)
      }
      else {
        let getPageStateInfo = await this.$auth.$api.get(
          `/api/pagestate/${this.getSelectedPageStateId}/info`
        );
        this.setWidgets(getPageStateInfo.data.data.widgets);
      }

      //update assets stored locally in component to rerender ui
      this.setComponentAssets()

      this.deleteAssetLoading = false;
      this.deleteDrawingDisabled = false;
      this.deleteDatasetDialog = false;
    },
    onFileUpload() {
      this.$refs.uploader.click();
    },
    async uploadFile(e) {
      // for await (const file of e.target.files) {
        let files = e.target.files;
        if (files != null && files != "") {
          if (this.assetInfo.name === "datasets") {
            let reader = new FileReader();
            reader.readAsText(files[0], "UTF-8");
            reader.onload = (evt) => {
              let text = evt.target.result;
              try {
                this.fileUploadFuntion(files);
              } catch (e) {
                this.snackbarTimeout = 2000;
                this.snackbarColor = "error";
                this.snackbarMessage =
                  "Sorry, your file doesn't appear to be valid JSON data.";
                this.snackbar = true;
              }
            };
            reader.onerror = (err) => {
              console.error(err);
            };
          } else {
            // console.log("files",files.length);
            this.fileUploadFuntion(files);
          }
        }
      // }
    },
   async fileUploadFuntion(files) {
    
      let uploadedFilesTos3 = 0; 
      let that = this;
      that.uploadingMsg = `uploading`;
      that.showFileUploadProgress = true;
      // that.uploadPercentage = 30;
      let path = `notebooks/${this.getNotebook._id}/${this.assetInfo.name}/`;
      that.uploadPercentage = 10;
      await utils.uploadModelsToS3(
        this.assetInfo.uploadUrl,
        files,
        path,
        this.getNotebook._id,
        async function (progresses, uploadedKey, fileName) {
          let fileLengths = 80 / files.length;
          if (uploadedKey) {
            // console.log("Creating new",uploadedKey);
            that.uploadingMsg =  "uploading" + " " +`${fileName}`;
            // that.uploadPercentage = 50;
            let allPageIds = that.getPagesStructuredData.map((page) => page._id);
            let postData = {
              name: fileName,
              s3_pointers: uploadedKey,
              pages: allPageIds,
              notebook: that.getNotebook._id,
            };
            let uploadAssetExtention = fileName.split('.').pop();
            let uploadAssetType;
            if (uploadAssetExtention === '3dm'){
              uploadAssetType = "models";
            }else if(uploadAssetExtention === 'svg'){
              uploadAssetType = "drawings";
            }
            else if (uploadAssetExtention === 'geojson'){
              uploadAssetType = "maps";
            }else{
              uploadAssetType = "datasets";
            }
           
            let newModel = await that.$auth.$api.post(
              `/api/${uploadAssetType}/new`,
              postData
            );
            // console.log("newModel",newModel);
            uploadedFilesTos3++;
            that.uploadPercentage = Math.round(that.uploadPercentage + fileLengths);
            // console.log("uploadedFilesTos3",uploadedFilesTos3);
             if(uploadedFilesTos3 === files.length){
              that.uploadingMsg = "Upload Done";
              that.uploadPercentage = 90;
              that.uploadPercentage = 95;

              await that.setAllAssetsInNotebook()
              that.setComponentAssets()
              
              that.uploadPercentage = 100;
              that.showFileUploadProgress = false;
              that.uploadPercentage = 0;
              that.uploadingMsg = "";
            }
            if (
              uploadAssetType === "datasets"
            ) {
              that.snackbarTimeout = 2000;
              that.snackbarColor = "primary";
              that.snackbarMessage =
                "Setting up dataset in project, this may take a while...";
              that.snackbar = true;
              await that.setSelectedPageDataset(that.getSelectedPageDatasets[0]);
            }
          }
        }
      );
    },
    async insertRemoveAssetsInWidget(asset) {
      //trigger checkbox loading
      let v = JSON.parse(JSON.stringify(this.loadingCheckbox));
      v[`${asset._id}`] = true;
      this.loadingCheckbox = v;

      let assetInWidget = this.checkAssetInWidget(asset);

      //if asset is already in widget ->remove it
      // else -> add it
      if (assetInWidget){
        let selectedWidgetAssetIDs = 
          this.getSelectedWidget.instance_setting.data.assets.map(asset => asset._id);
        let index = selectedWidgetAssetIDs.indexOf(asset._id);
        this.getSelectedWidget.instance_setting.data.assets.splice(index, 1)
      }
      else {
        this.getSelectedWidget.instance_setting.data.assets.push(asset);
      }

      //update assets stored locally in component to rerender ui
      this.setComponentAssets()
      this.loadingCheckbox[`${asset._id}`] = false;
    },

    async insertRemoveAssetIdInPageState(asset) {
      //trigger checkbox loading
      let v = JSON.parse(JSON.stringify(this.loadingCheckbox));
      v[`${asset._id}`] = true;
      this.loadingCheckbox = v;
      //Find widgets that contain the given asset type
      let relevantWidgets = this.getRelevantWidgets()

      let assetInPage = this.checkAssetInPage(asset)

      //if asset is already in widget ->remove it
      // else -> add it
      let updatedWidgets = []
      relevantWidgets.forEach(w => {
        if (assetInPage){ 
          let index = w.instance_setting.data.assets.map(a => a._id).indexOf(asset._id);
          w.instance_setting.data.assets.splice(index, 1)
        } 
        else {
          w.instance_setting.data.assets.push(asset)
        }
        let obj = {
          _id : w._id,
          assets: w.instance_setting.data.assets
        }
        updatedWidgets.push(obj);
      });
      let payload = {
        pageStateId: this.getSelectedPageStateId,
        assetId: asset._id,
        pageIds: this.getSelectedPage._id,
        widgets: updatedWidgets
      };
      // update page state and widget info in database
      if (assetInPage){
        await this.$auth.$api.post(`${this.assetInfo.removeUrl}`, payload)
          .then(async (remove) => {
            // console.log("removed", remove);
            this.snackbarColor = "warning";
            this.snackbarMessage = "Asset removed from the page";
            this.snackbar = true;
          })
          .catch((err) => {
            console.log("Err in removing", err);
          });
      }
      else {
        await this.$auth.$api.post(`${this.assetInfo.addAssetUrl}`, payload)
        .then(async (add) => {
          //console.log("added", add);
          this.snackbarColor = "primary";
          this.snackbarMessage = "Asset added to the page";
          this.snackbar = true;
        })
        .catch((err) => {
          console.log("Err in adding", err);
        });
      }

      //get assets and set all assets in notebook      
      await this.setAllAssetsInNotebook()

      //Need this to force the assets in the widget to be rerendered when the widget is selected
      this.setSelectedWidget(null)

      //update assets stored locally in component to rerender ui
      this.setComponentAssets()

      this.loadingCheckbox[`${asset._id}`] = false;
    },
    getRelevantWidgets(){
      let widgetsInPagestate = this.getWidgets
      let widgetIDsToUpdate
      if (this.assetInfo.name == 'drawings'){
        widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewer2d')
      }
      if  (this.assetInfo.name == 'models'){
        widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewer3d')
      }
      if (this.assetInfo.name == 'maps'){
        widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewerMap')
      }
      if (this.assetInfo.name == 'images'){
        widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewImageGrid')
      }
      return widgetIDsToUpdate
    }
  },
};
</script>

<style scoped>
.my-select .v-input__control {
  padding: 0 !important;
  margin: 0 !important;
}

.v-input--selection-controls {
  margin: 0px !important;
  padding: 0px !important;
}

.v-list {
  display: block;
  padding: 0px 0 !important;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0px 0px !important;
  position: relative;
  text-decoration: none;
}
</style>
