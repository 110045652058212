<template>
  <div class="widgetPadding" style="overflow-y: auto">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text" ></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading" ></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>
    
    <div class="flexColumn">
      <!-- Score Values -->
      <div v-if="getAttrData.length > 0 && grid_item.instance_setting.data" class="text-center mb-0">

        <template v-if="grid_item.instance_setting.data.selectedAttr != 'None'">
            <span v-if="grid_item.instance_setting.data.selectedScoreType == 'Unique Values'" class="results">{{this.scoreValues.length}}</span>

          <div
            v-else
            class="justify-space-between"
            v-for="scoreValue in this.scoreValues"
            :key="scoreValue"
          >
            <span class="results">
              {{ scoreValue }}
            </span>
          </div>
        </template>
      
<v-divider class="pb-1 pt-0"></v-divider>
      <!-- Score Text -->
            <div class="d-flex justify-center" >
              <div >
              <v-icon color="darkGrey" size="default" class="mr-1"> {{ getScoreTypeIcon }}</v-icon>
            </div>
            <div class="category ">
              <span> 
                {{ grid_item.instance_setting.data.selectedAttr }}
              </span>
              <br/>
                <small style="font-weight: 300;">{{grid_item.instance_setting.data.selectedScoreType}}</small>
            </div>
            
            <v-tooltip location="bottom" activator="parent"><span>{{ grid_item.instance_setting.data.selectedScoreType }} of {{ grid_item.instance_setting.data.selectedAttr }}</span></v-tooltip>
            </div>
      </div>

      <template
        v-if="
          grid_item.instance_setting.data &&
          grid_item.instance_setting.data.showFieldsSelectors
        "
      >
        <v-row density="compact" class="d-flex mt-0 pt-0" style="height: 50px">
          <v-combobox
            variant="underlined"
            hide-details="true"
            class="px-1"
            style="width: 50%"
            v-model="grid_item.instance_setting.data.selectedAttr"
            :items="getAttrHeaderOptions"
            density="compact"
            @update:modelValue="
              selectedAttrChanged(grid_item.instance_setting.data.selectedAttr)
            "
          />
          <v-combobox
          variant="underlined"
            hide-details="true"
            class="px-1"
            style="width: 50%"
            v-model="grid_item.instance_setting.data.selectedScoreType"
            :items="scoreTypes"
            :disabled="
              grid_item.instance_setting.data.selectedAttrType == 'String'
            "
            density="compact"
            @update:modelValue="
              scoreTypeChanged(grid_item.instance_setting.data.selectedAttr)
            "
          />
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getGlobalSignificantDigits
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeaders,
      getAttrHeaderOptions,
      getFilterByData
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
    } = notebookPropsStore
    return {assetsStore, notebookPropsStore, dataGraphicsStore,
    getEditMode, getAttrData, getAttrHeaders, getAttrHeaderOptions, getFilterByData,
    getSelectedPageDatasets, getDatasetLoading, getGlobalSignificantDigits
    }
  },
  data() {
    return {
      selectedAttr: "None",
      selectedAttrType: "String",
      selectedAttrValues: [],
      scoreTypes: [
        "Count",
        "Mean",
        "Median",
        "Range",
        "Min",
        "Max",
        "Unique Values",
        "Sum",
      ],
      selectedScoreType: "Count",
      scoreValues: ["Check Properties"],
      showFieldsSelectors: false,
      showFieldsSelectors:false,
      includeFilteredValues: false
    };
  },
  created() {
    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();
    if (!this.grid_item._id) {
      this.selectedAttrChanged();
    } else {
      this.selectedAttrChanged();
    }
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdateEvent()
        }
      })
    })
    //Listen for changes in the notebookPropsStore
    this.notebookPropsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setGlobalSignificantDigits'){
          this.selectedAttrChanged()
        }
      })
    })
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateFilterByProperties') {
          this.selectedAttrChanged()
        }
        if (name === "removeAllFilters"){
          this.selectedAttrChanged()
        }
      })
    })
  },
  watch: {
    "grid_item.instance_setting.data.selectedAttr": function() {
      this.selectedAttrChanged()
    },
    "grid_item.instance_setting.data.selectedScoreType": function() {
      this.scoreTypeChanged()
    },
    "grid_item.instance_setting.data.includeFilteredValues": function() {
      this.selectedAttrChanged()
    }
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    getScoreTypeIcon(){
      switch(this.grid_item.instance_setting.data.selectedScoreType){
        case "Count":
          return "mdi:mdi-pound"
        case "Median":
            return "mdi:mdi-poll"
        case "Mean":
          return "mdi:mdi-division"
        case "Range":
          return "mdi:mdi-plus-minus"
        case "Min":
          return "mdi:mdi-arrow-collapse-down"
        case "Max":
          return "mdi:mdi-arrow-collapse-up"
        case "Unique Values":
          return "mdi:mdi-diamond-stone"
        case "Sum":
          return "mdi:mdi-sigma"
      }
    }
  },
  methods: {
    verifyInstanceSettings() {

      if(!this.grid_item.instance_setting.data)this.grid_item.instance_setting.data={};
      if(!this.grid_item.instance_setting.data.scoreTypes)this.grid_item.instance_setting.data.scoreTypes = this.scoreTypes;
      if(!this.grid_item.instance_setting.data.showFieldsSelectors)this.grid_item.instance_setting.data.showFieldsSelectors = this.showFieldsSelectors;
      if(!this.grid_item.instance_setting.data.selectedScoreType)this.grid_item.instance_setting.data.selectedScoreType = this.selectedScoreType;
      if(!this.grid_item.instance_setting.data.selectedAttr)this.grid_item.instance_setting.data.selectedAttr = this.selectedAttr;
      if(!this.grid_item.instance_setting.data.selectedAttrType)this.grid_item.instance_setting.data.selectedAttrType = this.selectedAttrType;
      if(!this.grid_item.instance_setting.data.includeFilteredValues)this.grid_item.instance_setting.data.includeFilteredValues = this.includeFilteredValues
    },
    datasetUpdateEvent() {
      this.selectedAttrChanged();
    },
    scoreTypeChanged() {
      if (this.grid_item.instance_setting.data.selectedAttr != null) {
        this.grid_item.instance_setting.data.scoreValues = this.calculateScore(
          this.grid_item.instance_setting.data.selectedAttrValues
        );
      } else {
        this.grid_item.instance_setting.data.scoreValues = [
          "Check Properties 2",
        ];
      }
    },
    selectedAttrChanged() {
      if(this.grid_item.instance_setting.data.selectedAttr){
        let result =  this.getAttrHeaderOptions.filter(a => a === this.grid_item.instance_setting.data.selectedAttr);
        if(result.length === 0){
          this.grid_item.instance_setting.data.selectedAttr =  this.getAttrHeaderOptions[0];
        }
      }

      this.evaluateselectedAttr(
        this.grid_item.instance_setting.data.selectedAttr
      );

      if (this.grid_item.instance_setting.data.selectedAttrType == "String") {
        this.grid_item.instance_setting.data.selectedScoreType =
          "Unique Values";
      }
      this.grid_item.instance_setting.data.scoreValues = this.calculateScore(
        this.grid_item.instance_setting.data.selectedAttrValues
      );
    },
    evaluateselectedAttr(selectedAttr) {
      //Sets Attribute Type
      //Sets Selected Attribute Values withnulls and undefined removed.
      let headerIndex = this.getAttrHeaders.findIndex(
        (headder) => headder.name == selectedAttr
      );
      if (headerIndex === -1) return;
      this.grid_item.instance_setting.data.selectedAttrType =
        this.getAttrHeaders[headerIndex]["dataType"];
      //Remove empty and undefined values
      let elemIds = [];
      let elemVals = [];
      let includeFilteredValues = this.grid_item.instance_setting.data.includeFilteredValues
      let currentData = (this.getFilterByData.graphicData &&  includeFilteredValues) ? this.getFilterByData.graphicData : this.getAttrData
      currentData.forEach((attr) => {
        if (typeof attr[selectedAttr] != "undefined" || "") {
          elemVals.push(attr[selectedAttr]);
          elemIds.push(attr.ellipseId);
        }
      });
      //Set selected attribute values
      this.grid_item.instance_setting.data.selectedAttrValues = elemVals;
    },
    calculateScore(selectedAttrValues) {
      //Checks Selected Score Type and calculates Score
      let elemVals = selectedAttrValues ? selectedAttrValues : [];
      let ScoreVal = [];
      let numberVals = [];
      switch (this.grid_item.instance_setting.data.selectedScoreType) {
        case "Unique Values":
          ScoreVal = elemVals ? evaluate.getUniqueVals(elemVals) : null;
          if (!ScoreVal) return false;
          ScoreVal = ScoreVal.filter((element) => {
            if (element !== "") {
              return true;
            } else {
              return false;
            }
          });
          break;
        case "Count":
          numberVals = elemVals.map(function (val) {
            return +val;
          });
          ScoreVal = [elemVals.length];
          break;
        case "Sum":
          numberVals = elemVals.map(function (val) {
            return +val;
          });
          ScoreVal = [evaluate.getSum(numberVals)];
          break;
        case "Mean":
          numberVals = elemVals.map(function (val) {
            return +val;
          });
          ScoreVal = [evaluate.getMean(numberVals)];
          break;
        case "Median":
          numberVals = elemVals.map(function (val) {
            return +val;
          });
          ScoreVal = [evaluate.getMedian(numberVals)];
          break;
        case "Range":
          numberVals = elemVals.map(function (val) {
            return +val;
          });
          let range = evaluate.getRange(numberVals);
          ScoreVal = [
             String(evaluate.roundIfNeeded(range[0], this.getGlobalSignificantDigits))+' - '+String(evaluate.roundIfNeeded(range[1], this.getGlobalSignificantDigits))];
          break;
        case "Min":
          numberVals = elemVals.map(function (val) {
            return +val;
          });
          ScoreVal = [evaluate.getMin(numberVals)];
          break;
        case "Max":
          numberVals = elemVals.map(function (val) {
            return +val;
          });
          ScoreVal = [evaluate.getMax(numberVals)];
          break;
        default:
          ScoreVal = [
            "We had trouble calculating Score value. Please check the inputs and try again.",
          ];
      }
      ScoreVal = evaluate.roundArrayIfNeeded(ScoreVal, this.getGlobalSignificantDigits);
      ScoreVal = evaluate.getUniqueVals(ScoreVal);
      this.scoreValues = ScoreVal;
    },
  },
};
</script>

<style scoped>
.results{
  font-size:xx-large;
  font-weight: 500;
  color:  rgb(var(--v-theme-darkSlate));
}
.category{
  font-size:small;
  font-weight: 500;
  line-height: 100%;
  text-align: left;
  color:  rgb(var(--v-theme-darkSlate));
}
</style>
