<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

    <!-- Primary Content -->
    <div v-if="getAttrData.length > 0" class="text-center pt-0" style="height: 100%">
      <!-- Status Count -->
        <span class="results">{{numerator}}/{{denominator}}</span>

        <v-divider class="pb-1 pt-0"></v-divider>

        <div>
          <v-icon color="darkGrey" size="default">{{ icons.visible }}</v-icon> 
          <span class="category" > Visible </span>
          <v-tooltip location="bottom" activator="parent"><span>Filtered Elements</span></v-tooltip>
        </div>
    </div>
  </div>
</template>

<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: {Spinner, NoDataWarning, WidgetIcon, WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading
    } = storeToRefs(notebookPropsStore)
    const {
      getFilterByData,
      getAttrData
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    return {dataGraphicsStore, 
    getEditMode, getFilterByData, getAttrData, getSelectedPageDatasets, getDatasetLoading, icons}
  },
  data: () => ({
    numerator:null,
    denominator:null,
    datasetSwitchingLoading:false
  }),
  created(){
    this.verifyInstanceSettings();
  },
  mounted(){
    this.verifyInstanceSettings();
    this.numerator = this.getFilterByData.graphicData ? this.getFilterByData.graphicData.length : this.getAttrData.length
    this.denominator = this.getAttrData.length

    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
        if (name === "removeAllFilters"){
          this.numerator = this.getAttrData.length;
          this.denominator = this.getAttrData.length;
        }
      })
    })
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {

    },
    async onNewFilterByUpdated() {
      this.numerator = this.getFilterByData.graphicData ? this.getFilterByData.graphicData.length : this.getAttrData.length
    },
  },
};
</script>
<style scoped>
.results{
  font-size:xx-large;
  font-weight: 500;
  color:  rgb(var(--v-theme-darkSlate));
}
.category{
  font-size:small;
  font-weight: 500;
  color:  rgb(var(--v-theme-darkSlate));
}
</style>
