<template>
  <!-- Wrapper -->
  <v-card style="font-weight: 500" flat>

    <!-- wrapper content -->
    <v-card-text class="pa-0 ma-0 defaultText black--text">

      <!-- Check if widget has settings. -->
      <div v-if="selectedWidgetDetails.hasSettings">

        <!-- insert widget settings component. -->
        <component 
          v-bind:is="getSelectedWidget.content+'_settings'"  
          :getSelectedWidget="getSelectedWidget" 
          :notebook="this.getNotebook" 
        />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import ChartBar_settings from "./widgets/ChartBar_settings.vue";
import ChartDonut_settings from "./widgets/ChartDonut_settings.vue";
import ChartHistogram_settings from "./widgets/ChartHistogram_settings.vue";
import ChartLine_settings from "./widgets/ChartLine_settings.vue";
import ChartParallel_settings from "./widgets/ChartParallel_settings.vue";
import ChartScatter_settings from "./widgets/ChartScatter_settings.vue";
import DisplayScoreCard_settings from "./widgets/DisplayScoreCard_settings.vue";
import DisplayStatistics_settings from "./widgets/DisplayStatistics_settings.vue";
import DisplayFilterStatus_settings from "./widgets/DisplayFilterStatus_settings.vue";
import FilterByCards_settings from "./widgets/FilterByCards_settings.vue";
import FilterByCheck_settings from "./widgets/FilterByCheck_settings.vue";
import FilterByCounter_settings from "./widgets/FilterByCounter_settings.vue";
import FilterByDropdown_settings from "./widgets/FilterByDropdown_settings.vue";
import FilterByRange_settings from "./widgets/FilterByRange_settings.vue";
// import FilterByValue_settings from "./widgets/FilterByValue_settings.vue";
import StaticCarousel_settings from "./widgets/StaticCarousel_settings.vue";
import StaticIframe_settings from "./widgets/StaticIframe_settings.vue";
import StaticImage_settings from "./widgets/StaticImage_settings.vue";
import StaticRichText_settings from "./widgets/StaticRichText_settings.vue";
import StaticSpacer_settings from "./widgets/StaticSpacer_settings.vue";
import Viewer2d_settings from "./widgets/Viewer2D_settings.vue";
import Viewer3d_settings from "./widgets/Viewer3D_settings.vue";
import ViewerMap_settings from "./widgets/ViewerMap_settings.vue";
import ViewImageGrid_settings from "./widgets/ViewImageGrid_settings.vue";
import ViewDataTable_settings from "./widgets/ViewDataTable_settings.vue";
import ViewItemList_settings from "./widgets/ViewItemList_settings.vue";
import ViewLegend_settings from "./widgets/ViewLegend_settings.vue";
import ViewFileAttachments_settings from "./widgets/ViewFileAttachments_settings.vue";
import {ui_element_widgets} from "../utilities/WidgetsCollection.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";

export default {
  components: {
    ChartBar_settings,
    ChartDonut_settings,
    ChartHistogram_settings,
    ChartLine_settings,
    ChartParallel_settings,
    ChartScatter_settings,
    DisplayScoreCard_settings,
    DisplayStatistics_settings,
    DisplayFilterStatus_settings,
    FilterByCounter_settings,
    FilterByCheck_settings,
    FilterByCards_settings,
    FilterByRange_settings,
    FilterByDropdown_settings,
    StaticIframe_settings,
    StaticImage_settings,
    StaticCarousel_settings,
    StaticRichText_settings,
    StaticSpacer_settings,
    ViewItemList_settings,
    ViewDataTable_settings,
    ViewLegend_settings,
    Viewer3d_settings,
    Viewer2d_settings,
    ViewerMap_settings,
    ViewImageGrid_settings,
    ViewFileAttachments_settings
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedWidget,
    } = storeToRefs(notebookPropsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    return {notebookPropsStore, 
    getSelectedWidget, getNotebook}
  },
  data: () => ({}),
  computed: {
    selectedWidgetDetails(){
      let selectedWidget = this.getSelectedWidget.content
      let WidgetDetails = ui_element_widgets.find((widget) => widget.name == selectedWidget)
      return WidgetDetails;
    }
  },
  async created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.container {
  height: 35rem;
}

.borderLine {
  border: 1px solid rgb(var(--v-theme-darkSlate));
}
.v-radio label {
  font-size: 100px;
}

.editor {
  height: 82%;
  width: 100%;
}
.v-treeview {
  width: max-content;
}
.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
