<template>
  <v-container class="pt-1">
    <!-- Widges Instructions and Headder. -->
    <v-row>
      <HelpAlert :message="messages.widgetAlert" />
    </v-row>

    <!-- Widget Grid. -->
    <v-row v-for="category in ui_categories" :key="category" class="d-flex flex-wrap pt-2">
      <h5 class="pl-1">
        {{category}}
      </h5>
      <v-divider class="pb-1"></v-divider>

      <!-- Widget. -->
      <v-col cols="12" md="4" class="ma-0 pa-0" v-for="element_widgets in ui_categorical_element_widgets[category]"
        :key="element_widgets.name">
            <!-- Widget Button. -->
            <v-btn width="6rem" height="3rem" variant="plain" elevation="3"
              @drag="drag(`${element_widgets.name}`)" @dragend="dragend(`${element_widgets.name}`)"
              class="droppable-element mx-1 my-1" draggable="true" unselectable="on">
              <!-- Widget Button Content. -->
              <div class="pa-1">
                <v-icon color="darkSlate" :size="element_widgets.iconsize">
                  {{ element_widgets.icon }}
                </v-icon>
                <br>
                <small>{{ element_widgets.text }}</small>
              </div>
              <v-tooltip location="top" activator="parent"><span>Click and Drag Widget onto the Canvas</span></v-tooltip>
<HelpCard :title="element_widgets.text" :message="element_widgets.description" :link="element_widgets.documentation" />
            </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import bus from "@/plugins/bus.js";
import HelpAlert from "../components/ui/HelpAlert.vue";
import HelpCard from "../components/ui/HelpCard.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";

export default {
  components: {
    HelpAlert,
    HelpCard,
  },
  setup() {
    return {
      lexicon,
      messages,
    }
  },
  data() {
    return {
      ui_element_widgets: [],
      ui_categorical_element_widgets: {},
      ui_categories: [],
    };
  },
  computed: {
  },
  created() {},
  mounted() {
    this.ui_element_widgets = WidgetsCollection.ui_element_widgets;
    Object.keys(WidgetsCollection.widget_categories).forEach(key => {this.ui_categories.push(WidgetsCollection.widget_categories[key].name) });
    
    this.ui_categories.forEach(category => {
      this.ui_categorical_element_widgets[category]=[];
      this.ui_element_widgets.forEach(widget => {
        if(widget.type.name === category) this.ui_categorical_element_widgets[category].push(widget);
      });
    });
  },
  methods: {
    drag(widgetName) {
      bus.emit("widget-selection-drag", widgetName);
    },
    dragend(widgetName) {
      bus.emit("widget-selection-dragend", widgetName);
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.droppable-element {
  text-align: center;
  background: white;
}
.droppable-element:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
  border-width: 1px;
  border-color: rgb(var(--v-theme-darkGrey));
}
.grid-stack-item {
  margin: 0px;
}
.grid-stack-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(var(--v-theme-darkGrey));
  background-color: #BEE3F8;
  font-weight: 600;
  box-shadow: 0 1px 3px 0 rgb(var(--v-theme-darkSlate), 0.1), 0 1px 2px 0 rgb(var(--v-theme-darkSlate), 0.6);
}

.text-caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 0.8rem !important;
  letter-spacing: 0.02em !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
