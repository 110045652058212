<template>
  <div class="widgetPadding" id="legend-container" style="height: 100%; width: 100%; overflow-y: scroll;">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="!colorByMode && !isFixed" :icon="widgetIcon.icon">No Color By applied</NoDataWarning>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

    <!--Legend. -->
    <div v-show="colorByMode">

      <!-- Legend Content Type. -->
      <v-container class="pl-0 px-1 py-0 d-flex justify-space-between">
      <v-col v-if="getColorByData && !isFixed">
        <h5 class="WidgetSubHeader">{{ getGlobalColorByAttribute }}</h5>
      </v-col>
      </v-container>

      <div v-if="isCategorical && legendDictionary !== undefined && legendDictionary !== null">
        <!-- Categorical Horizontal. -->
        <div v-if="grid_item.instance_setting.data.horizontal">
          <v-chip-group
            column
          >
            <v-chip 
              v-for="key in Object.keys(legendDictionary)" 
              :key="key"
              variant="text"
              class="legandList pa-0 ma-0"
              size="small"
              label
              :ripple="false"
              selected-class="text-primary"
              @click="onClickCategorical(key,$event)"
            >
                <v-menu>
                  <template v-slot:activator="{ props}">
                    <v-btn 
                      v-bind="props" 
                      variant="text"
                      class="ma-0 pa-0"
                      icon 
                      density="comfortable"
                      size="small"
                    >
                      <v-icon
                        :color="legendDictionary[key]"
                      >
                        mdi:mdi-square
                      </v-icon>
                    </v-btn>
                </template>
                <v-color-picker
                  v-model="legendDictionary[key]"
                  dot-size="4"
                  canvas-height="80"
                  width="300"
                  swatches-max-height="10"
                  :modes="['rgb']"
                  @update:modelValue="(color) => legendSwatchUpdated(color, key)"
                ></v-color-picker>
              </v-menu>
              <span 
                class="text-truncate pr-1"
                style="max-width: 100px;"
              >
                {{ key }}
                <v-tooltip location="bottom" activator="parent">{{key}}</v-tooltip>
              </span>
            </v-chip>
          </v-chip-group>
        </div>
        <!-- Categorical Vertical. -->
        <div v-else>
          <v-list 
            class="legandList pa-0"
            density="compact"
          >
            <v-list-item
              v-for="key in Object.keys(legendDictionary)" 
              style="overflow-y: hidden;"
              :key="key"
              class="mt-0 mb-0 pa-0"
              density="compact"
              size="small"
              min-height="20"
              label
              :ripple="false"
              height="20px"
              @click="onClickCategorical(key, $event)"
            >
                <v-menu>
                  <template v-slot:activator="{ props}">
                    <v-btn 
                      v-bind="props" 
                      icon 
                      size="small"
                      variant="text"
                      class="ma-0 pa-0"
                      density="comfortable"
                      close-on-content-click="false"
                    >
                      <v-icon
                        :color="legendDictionary[key]"
                        size="20"
                      >
                        mdi:mdi-square
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-color-picker
                      v-model="legendDictionary[key]"
                      dot-size="4"
                      canvas-height="80"
                      width="300"
                      swatches-max-height="10"
                      :modes="['rgb']"
                      @update:modelValue="(color) => legendSwatchUpdated(color, key)"
                    ></v-color-picker>
                  </v-card>
                </v-menu>
                <span 
                  class="text-truncate"
                  style="font-size: small;"
                >
                  {{ key }}
                  <v-tooltip location="bottom" activator="parent">{{key}}</v-tooltip>
                </span>
            </v-list-item>
          </v-list>
        </div>
      </div>
      <div v-else>
        <!-- Slider -->
        <v-range-slider 
          class="CustomSlider resizeGradientRange align-center pt-1 pb-0 pl-2 pr-3 mb-6" 
          :style="getGradientCSS"
          :modelValue="getActiveRange" 
          :max="getGradientRange[1]" 
          :min="getGradientRange[0]" 
          thumb-color="grey lighten-23"
          :ticks="getGradientLabels"
          tick-size="3"
          show-ticks="always"
          hide-details
          :direction="!grid_item.instance_setting.data.horizontal ? 'vertical' : 'horizontal'"
          color="black" 
          track-color="darkGrey"
          @update:modelValue="setRange($event)"
        >

          <!-- Left Side Min. -->
          <template v-slot:prepend>
            <v-text-field 
            v-if="grid_item.instance_setting.data.inputs" 
              :modelValue="$filters.round(getActiveRange[0], 1)" 
              class="centered-input mt-0 pt-0" 
              hide-details 
              single-line 
              density="compact" 
              type="number" 
              style="width: 80px" 
              variant="underlined"
              @update:modelValue="setRangeMin($event)"
            >
            </v-text-field>
          <v-spacer v-else ></v-spacer>
          </template>
          <!-- Right Side Max. -->
          <template v-slot:append>
            <v-text-field 
            v-if="grid_item.instance_setting.data.inputs"
              :modelValue="$filters.round(getActiveRange[1],2)" 
              class="centered-input mt-0 pt-0" 
              hide-details 
              single-line 
              density="compact" 
              type="number"
              style="width: 80px"  
              variant="underlined"
              @update:modelValue="setRangeMax($event)"
            ></v-text-field>
          <v-spacer v-else ></v-spacer>
          </template>
        </v-range-slider>
      </div>
    </div>    
  </div>
</template>

<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue"
import Spinner from "../ui/Spinner.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue"

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";

export default {
  components: { NoDataWarning, WidgetIcon, Spinner, WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getEditMode,
      getGlobalColorByAttribute,
      getGlobalColorByGradient,
      getGlobalColorByRange,
      getDatasetLoading,
      getGlobalSignificantDigits
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
    } = storeToRefs(dataGraphicsStore)
    const {
      addRangeToSelectedElements
    } = notebookPropsStore
    const {
      updateColorByProperties
    } = dataGraphicsStore
    return {
      notebookPropsStore,
      dataGraphicsStore,
      getEditMode,
      getDatasetLoading,
      getColorByData,
      getGlobalColorByAttribute,
      getGlobalColorByGradient,
      getGlobalColorByRange,
      updateColorByProperties,
      addRangeToSelectedElements,
      getGlobalSignificantDigits
    }
  },
  data() {
    return {
      canvasId:"legend-canvas",
      legendId:"legend-container",
      frameId:"legend-frame",
      legendDictionary: null,
      width: 20,
      angle:30,
      horizontal: false,
      hasInputs: false,
      textsize: 12,
      colorByMode: false,
      legendDigits: 4,
      datasetSwitchingLoading: false,
      isFixed: false,
      isCategorical:true,
      slider: {
        range: [-5, 100],
        min: -5,
        max: 100,
      },
      tickCount:6,
    };
  },
  computed: {
    getActiveRange() {
      let range = this.getGlobalColorByRange

      //TODO - range is missing on load, need closer look for true fix. this is just trying to get page to laod.
      if (this.getColorByData.graphicData == undefined) return [0,1];
      
      if(range == undefined) return this.getColorByData.graphicData.range;
      
      if( range.length == 2 ){
        return range;
      } else {
        return this.getColorByData.graphicData.range;
      }
    },
    getGradientCSS() {
      let cssObj = {};
      //let gradient = `linear-gradient(to right, ${this.getGlobalColorByGradient.join(", ")})`;
      //cssObj['--background-image'] = gradient;
      if(this.getGlobalColorByGradient == null)return;
      if(!this.grid_item.instance_setting.data.horizontal){
        cssObj['--background-image'] = `linear-gradient(to top, ${this.getGlobalColorByGradient.join(", ")})`;
        cssObj['--gradientHeight'] = '100%';
        cssObj['--gradientWidth'] = this.width+'px';
        cssObj['--labelAngle'] = this.angle+'deg';
      } else {
        cssObj['--background-image'] = `linear-gradient(to right, ${this.getGlobalColorByGradient.join(", ")})`;
        cssObj['--gradientHeight'] = this.width+'px';
        cssObj['--gradientWidth'] = '100%';
        cssObj['--labelAngle'] = this.angle+'deg';
      }
      return cssObj;
    },
    getGradientLabels(){
      const count = this.tickCount;
      const min = this.getGradientRange[0]
      const max = this.getGradientRange[1]
      const step = (max - min) / count;

      let marks = [];
      for (let i = 0; i <= count; i++) {
        marks.push((min + step * i).toFixed(this.getGlobalSignificantDigits));
      }

      return marks;
    },
    getGradientLabelSteps(){
      const count = this.tickCount;
      const min = this.getGradientRange[0]
      const max = this.getGradientRange[1]
      let step = (max - min) / count;
      return step;
    },
    getGradientRange(){
      let globalColorBy = this.getColorByData;
      if(globalColorBy.graphicData == undefined || globalColorBy.graphicData == null) return [0,1];
      let range = globalColorBy.graphicData.range;
      return range;
    },
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  async created() {

    if(this.grid_item.instance_setting.isPanelWidget) this.isFixed = this.grid_item.instance_setting.isPanelWidget;

    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();

    if (this.getColorByData.graphicData) {
      this.colorByMode = true;
      this.legendDictionary = this.getColorByData.graphicData.legendDict;
    }

    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByData') {
          this.onNewColorByUpdated()
        }
      })
    })
  },
  updated() {
    if (this.getColorByData.graphicData) {
      this.colorByMode = true;
      this.legendDictionary = this.getColorByData.graphicData.legendDict;
      this.isCategorical = !this.isNumeric(this.legendDictionary);
    }
  },
  watch: {
    "grid_item.instance_setting.data.width": function () {
      if (this.getColorByData.graphicData) this.onWidthChange();
    },
    "grid_item.instance_setting.data.angle": function(){
      if (this.getColorByData.graphicData) this.onAngleChange();
    }
  },
  methods: {
    verifyInstanceSettings() {

      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      if (!('width' in this.grid_item.instance_setting.data))  this.grid_item.instance_setting.data.width = this.width;
      if (!('angle' in this.grid_item.instance_setting.data))  this.grid_item.instance_setting.data.angle = this.angle;
      if (!('horizontal' in this.grid_item.instance_setting.data))  this.grid_item.instance_setting.data.horizontal = this.horizontal;
      if (!('textsize' in this.grid_item.instance_setting.data))  this.grid_item.instance_setting.data.textsize = this.textsize;
      if (!('inputs' in this.grid_item.instance_setting.data))  this.grid_item.instance_setting.data.inputs = this.hasInputs;

      this.width = this.grid_item.instance_setting.data.width;
      this.angle = this.grid_item.instance_setting.data.angle;
      this.horizontal = this.grid_item.instance_setting.data.horizontal;
      this.textsize = this.grid_item.instance_setting.data.textsize;
      this.hasInputs = this.grid_item.instance_setting.data.inputs;

      if (!this.grid_item._id) {
        if (this.grid_item.instance_setting.isPanelWidget){
          this.grid_item.instance_setting.data.horizontal = true;
          this.horizontal = true;
          this.grid_item.instance_setting.data.inputs = false;
          this.hasInputs = false;
        }
      };
    },
    legendSwatchUpdated(color, key){
      //convert hex to rgb
      let hex= color.split('#')[1]
      let rgbPairs = hex.match(/.{1,2}/g);
      let rgb = [parseInt(rgbPairs[0], 16), parseInt(rgbPairs[1], 16), parseInt(rgbPairs[2], 16)]
      const rgbaColor = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
      this.legendDictionary[key] = rgbaColor;
      this.updateColorByProperties({
        legendDict: this.legendDictionary
      })
    },
    async onNewColorByUpdated() {
      let opts = this.getColorByData.graphicData;
      if (!opts) {
        this.colorByMode = false;
        return;
      }
      this.colorByMode = true;
      this.legendDictionary = this.getColorByData.graphicData.legendDict;
      this.isCategorical = !this.isNumeric(this.legendDictionary);
      if(!this.isCategorical){
           let min = Math.min(Object.keys(this.getColorByData.graphicData.legendDict))
           let max = Math.max(Object.keys(this.getColorByData.graphicData.legendDict))
           this.slider = {
             range: [min, max],
             min: min,
             max: max,
           };
      }
      this.datasetSwitchingLoading = false;
    },
    isNumeric(gradDict) {
      var attributes = Object.keys(gradDict);
      var isNumeric = !attributes.some(isNaN);
      return isNumeric;
    },
    onClickCategorical(selectedValue, e){
      let selectedElements = [];
      let values = this.getColorByData.graphicData.values;
      let ids = this.getColorByData.graphicData.ids;
      //get array of ids with selectedValue
      for(let i=0; i<values.length; i++){
        if(values[i] == selectedValue){
          selectedElements.push(ids[i]);
        }
      }
      if(e.ctrlKey){
        this.addRangeToSelectedElements(selectedElements)
      }else{
      this.notebookPropsStore.$patch({selectedElements: selectedElements})
      }
    },
    onWidthChange() {
      this.width = this.grid_item.instance_setting.data.width;
    },
    onAngleChange() {
      this.angle = this.grid_item.instance_setting.data.angle;
    },
    onInputsChange() {
      this.hasInputs = this.grid_item.instance_setting.data.inputs;
    },
    setRange(range){
      this.updateColorByProperties({range:range});
    },
    setRangeMin(min){
      this.updateColorByProperties({range:[min, this.getActiveRange[1]]});
    },
    setRangeMax(max){
      this.updateColorByProperties({range:[this.getActiveRange[0], max]});
    }
  },
};
</script>
<style scoped>
.col {
  padding: 0px;
}
.v-input--horizontal:deep(.v-slider-track__background){
  height: var(--gradientHeight) !important;
  border-radius: 0 !important;
}
.v-input--horizontal:deep(.v-slider-track__fill.bg-black){
  height: var(--gradientHeight) !important;
  background-image: var(--background-image) !important;
  border-radius: 0 !important;
}
.v-input--horizontal:deep(.v-slider-track__tick-label){
  transform: translateX(0%) rotate(30deg) translateY(calc(var(--gradientHeight)/2.2)) !important;
  transform-origin: center left !important;
  font-size: x-small !important;
}
.v-input:deep(.text-grey){
  color: white !important;
}

.v-input--vertical:deep(.v-slider-track__background){
  min-width: var(--gradientWidth) !important;
  width: var(--gradientWidth) !important;
  height: 300px !important;
  border-radius: 0 !important;
}
.v-input--vertical:deep(.v-slider-track__fill.bg-black){
  width: var(--gradientWidth) !important;
  background-image: var(--background-image) !important;
  border-radius: 0 !important;
}
.v-input--vertical:deep(.v-slider-track__tick-label){
  font-size: x-small !important;
  transform: translateY(-50%) translateX(calc(var(--gradientWidth)/2)) !important;
  transform-origin: center left;
}
.v-input--vertical:deep(.v-slider-track__ticks){
  height: 300px !important;
}
.centered-input :deep(input) {
  text-align: center;
}
</style>
