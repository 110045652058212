<template>
    <v-menu 
        activator="parent" 
        attach="parent"
        open-on-hover offset-y 
        :close-on-content-click="true">

        <v-list density="compact">
            <v-list-subheader>MODELS</v-list-subheader>
            <v-list-item v-for="(item, i) in models" :key="i" density="compact" class="ma-1"
                @click="modelUpdated(item)">
                <v-list-item-title>{{ item.cleanName }}</v-list-item-title>
            </v-list-item>

        </v-list>
    </v-menu>
</template>
<script>
import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

export default {
    props: ["models", "selectedModel"],
    data() {
        return {
            closeOnContentClick: false,
        };
    },
    setup() {
        const notebookPropsStore = useNotebookPropsStore()
        const {
            getEditMode,
        } = storeToRefs(notebookPropsStore)
        const {
        } = notebookPropsStore
        return { getEditMode }
    },
    computed: {},
    methods: {
        modelUpdated(item) {
            this.$emit("modelUpdated", item);
        },
    },
};
</script>
<style scoped></style>