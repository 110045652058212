import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { graphic, filtering, evaluate } from "@ttcorestudio/data-processor";
import {useNotebookPropsStore} from '@/store/NotebookPropsStore.js'
import { searchByKeyword } from '@ttcorestudio/data-processor/_filtering';
import {isNullOrUndefined} from "@/utilities"

export const useDataGraphicsStore = defineStore('DataGraphics', () =>{

  //State Variables
  const attrData = ref([])
  const attrHeaders = ref([])

  const colorByData = ref({
    graphicData:null
  })
  const labelByData = ref({
    attribute:"None", 
    colors:[],
    graphicData:null
  })
  const filterByData = ref({})

  //All Filters
  const memoizedList = ref([])
  const allFilters = ref([])
  const filterStartPointer = ref(null)
  const filteredInEllipseIds = ref([])
  const filteredOutEllipseIds = ref([])
  
  //Getters
  const getAttrData = computed(() => attrData.value)
  const getAttrHeaders = computed(() => attrHeaders.value)
  const getAttrHeaderNames = computed(() => {
    return attrHeaders.value.filter(header => Array.from(header.name)[0]!=='_').map((header) => header.name)
  })
  const getAttrHeaderOptions = computed(() => {
    return ["None", ...getAttrHeaderNames.value]
  })
  const getAttrHeadersCategorical = computed(() => {
    let attr = getAttrHeaders.value.reduce(function (acc, item) {
      if (item.dataType === "String") acc.push(item.name);
      return acc;
    }, []);
    return ["None", ...attr]
  })
  const getAttrHeadersNumerical = computed(() => {
    let attr = getAttrHeaders.value.reduce((acc, item) => {
      if (item.dataType === "Number") acc.push(item.name);
      return acc;
    }, []);
    return attr
  })

  const getAttrByHeader = computed(() => {
    let attr = {}
    getAttrHeaderNames.value.forEach(name => {
      attr[name] = getAttrData.value.map(a => a[name]);
    });    
    return attr
  })
  
  const getUniqueAttrByHeader = computed(() => { 
    let attr = {}
    getAttrHeaderNames.value.forEach(name => {
      attr[name] = new Set(getAttrData.value.map(a => a[name]));
    });    
    return attr
  })

  const getUniqueAttrTotalByHeader = computed(() => { 
    let list = []
    getAttrHeaderNames.value.forEach(name => {
      list.push({'name':name, 'total':new Set(getAttrData.value.map(a => a[name])).size});
    });    

    list.sort(function(a, b) { return ((a.total < b.total) ? -1 : ((a.total == b.total) ? 0 : 1));});

    return list
  })

  const getAttrByNumericalHeader = computed(() => {
    let attr = {}
    getAttrHeadersNumerical.value.forEach(name => {
      attr[name] = getAttrData.value.map(a => a[name]);
    });    
    return attr
  })
  
  const getUniqueAttrByNumericalHeader = computed(() => { 
    let attr = {}
    getAttrHeadersNumerical.value.forEach(name => {
      attr[name] = new Set(getAttrData.value.map(a => a[name]));
    });    
    return attr
  })

  const getUniqueAttrTotalByNumericalHeader = computed(() => { 
    let list = []
    getAttrHeadersNumerical.value.forEach(name => {
      list.push({'name':name, 'total':new Set(getAttrData.value.map(a => a[name])).size});
    });    

    list.sort(function(a, b) { return ((a.total < b.total) ? -1 : ((a.total == b.total) ? 0 : 1));});

    return list
  })

  const getAttrByCategoricalHeader = computed(() => {
    let attr = {}
    getAttrHeadersCategorical.value.forEach(name => {
      attr[name] = getAttrData.value.map(a => a[name]);
    });    
    return attr
  })
  
  const getUniqueAttrByCategoricalHeader = computed(() => { 
    let attr = {}
    getAttrHeadersCategorical.value.forEach(name => {
      attr[name] = new Set(getAttrData.value.map(a => a[name]));
    });    
    return attr
  })

  const getUniqueAttrTotalByCategoricalHeader = computed(() => { 
    let list = []
    getAttrHeadersCategorical.value.forEach(name => {
      list.push({'name':name, 'total':new Set(getAttrData.value.map(a => a[name])).size});
    });    

    list.sort(function(a, b) { return ((a.total < b.total) ? -1 : ((a.total == b.total) ? 0 : 1));});

    return list
  })



  const getColorByData = computed(() => colorByData.value)
  const getLabelByData = computed(() => labelByData.value)
  const getFilterByData = computed(() => filterByData.value)
  const getAllFilters = computed(() => allFilters.value)

  const getFilteredInEllipseIds = computed(() => filteredInEllipseIds.value)
  const getFilteredOutEllipseIds = computed(() => filteredOutEllipseIds.value)

  //Actions
  async function setAttrData(newAttrData) {
    attrData.value = newAttrData
  }
  async function setFilteredEllipseIds(data){
    let currentData = data ? data : attrData.value
    filteredInEllipseIds.value = currentData.map(data => data.ellipseId)
    let filteredInEllipseIdsSet = new Set(filteredInEllipseIds.value);
    let outOrRangeData = attrData.value.filter(data => !filteredInEllipseIdsSet.has(data.ellipseId))
    filteredOutEllipseIds.value = outOrRangeData.map(data => data.ellipseId)
  }
  async function setAttrHeaders(newAttrHeaders) {
    attrHeaders.value = newAttrHeaders
  }
  async function setColorByData(data) {
    if (data === null){
      colorByData.value = {
        attribute:"None", 
        graphicData:null
      }
      return
    }
    if (data.attribute){
      colorByData.value['attribute'] = data.attribute
    }
    if (data.graphicData){
      colorByData.value['graphicData']= data.graphicData
    } 
    if (data.graphicData === null){
      colorByData.value['graphicData'] = null
    }
  }
  async function setLabelByData(data) {
    if (data === null){
      labelByData.value = {
        attribute:"None", 
        colors:[],
        graphicData:null
      }
      return
    }
    if (data.attribute){
      labelByData.value['attribute'] = data.attribute
    }
    if (data.colors){
      labelByData.value['colors'] = data.colors
    }
    if (data.graphicData){
      labelByData.value['graphicData'] = data.graphicData
    } 
    if (data.graphicData === null){
      labelByData.value['graphicData'] = null
    }
  }
  async function setFilterByData(data){
    //data schema of graphicData
      //-data.graphicData = { 0: globalInRange, 1: globalOutOfRange }
    if (data.graphicData) {
      filterByData.value.graphicData = data.graphicData;
    }
  }

  async function updateColorByProperties(data) {
    const notebookPropsStore = useNotebookPropsStore()
    let computeGraphicData = false;
    if (data.attribute && data.attribute !== notebookPropsStore.getGlobalColorByAttribute){
      notebookPropsStore.setGlobalColorByAttribute(data.attribute);
      notebookPropsStore.setGlobalColorByRange([]);
      notebookPropsStore.$patch({globalPropertyUpdated:true})
      computeGraphicData = true;
    }
    if (data.gradient && 
        JSON.stringify(data.gradient) !== JSON.stringify(notebookPropsStore.getGlobalColorByGradient)){
      notebookPropsStore.setGlobalColorByGradient(data.gradient);
      notebookPropsStore.$patch({globalPropertyUpdated:true})
      computeGraphicData = true;
    }
    if (data.range && data.range !== notebookPropsStore.getGlobalColorByRange){
      notebookPropsStore.setGlobalColorByRange(data.range);
      computeGraphicData = true;
    }
    if (data.legendDict){
      updateColorByLegend(data.legendDict)
      return
    }
    if (computeGraphicData){
      this.updateColorByData();
    }
  }

  async function updateColorByData() {
    const notebookPropsStore = useNotebookPropsStore()
    let attrs = attrData.value;
    let color_by_attribute = notebookPropsStore.getGlobalColorByAttribute;
    let color_by_gradient = notebookPropsStore.getGlobalColorByGradient;
    let color_by_range = notebookPropsStore.getGlobalColorByRange;
  
    //If attriburtes or colors are null
    //than clear the colorby returning to defualt.
    if (
      (color_by_attribute === undefined ||
      color_by_attribute === null ||
      color_by_attribute === "None" ||
      color_by_gradient.length == 0) 
    ) {
      if(colorByData.value.graphicData !== null){
        setColorByData({graphicData:null,attribute:"None"});
      }
      return;
    }
  
    //check if color_by_range is valid
    let customRange = false;
    if(color_by_range != undefined && color_by_range != null){
      customRange = color_by_range.length == 2;
    }
  
    //Get all element ID's and thier assositated
    //value for the colorby attribute. 
    let elemIds = [];
    let elemVals = [];
    attrs.forEach((attr) => {
      elemIds.push(attr.ellipseId);
      elemVals.push(attr[color_by_attribute]);
    });
  
    //Build graphic object with data processor. 
    let graphicData = null;
    //color_by_range is an array [min, max] if [] then inactive. 
    if(customRange){
      graphicData = graphic.colorBy(
        elemIds,
        elemVals,
        color_by_gradient,
        color_by_range
      );
    } else {
      graphicData = graphic.colorBy(
        elemIds,
        elemVals,
        color_by_gradient
      );
    }
  
    //Commit new global colorby and emit update event. 
    if (graphicData) {
      setColorByData({graphicData:graphicData, attribute:color_by_attribute});
    }
  }

  async function updateColorByLegend(data){
    let legendDict = data;
    let newColorby = {...colorByData.value}
    newColorby.graphicData.legendDict = legendDict;
    newColorby.graphicData.colors = newColorby.graphicData.values.map((val) => {return legendDict[val]});
    setColorByData(newColorby)
  }

  /**
   * This is used to update @see store.globalColorBy.graphicData
   * and should be called whenever changes to the 
   * @see store.globalColorBy attribute, or collors
   * are updated. 
   */
  async function updateLabelByProperties(labelByProps) {
    let labelByAttribute = labelByProps.attribute;
    let labelByColors = labelByProps.colors;

    //If attriburtes or colors are null
    //than clear the colorby returning to defualt.
    if (
      labelByAttribute === undefined ||
      labelByAttribute === null ||
      labelByAttribute === "None" ||
      labelByColors.length == 0
    ) {
      setLabelByData({
        attribute: labelByAttribute,
        colors: labelByColors,
        graphicData: null,
      }); // for sticky visualization
      return;
    }

    //Get all element ID's and thier assositated
    //value for the colorby attribute. 
    let elemIds = [];
    let elemVals = [];
    attrData.value.forEach((item) => {
      elemIds.push(item.ellipseId);
      elemVals.push(item[labelByAttribute]);
    });

    //Build graphic object with data processor. 
    let graphicData = graphic.colorBy(
      elemIds,
      elemVals,
      labelByColors
    );

    //Commit new global colorby and emit update event. 
    if (graphicData) {
      setLabelByData({
        attribute: labelByAttribute,
        colors: labelByColors,
        graphicData: graphicData,
      });
    }
  }

  async function updateFilterByProperties(){
    //do check if loading, and set filters
    let filters = allFilters.value
    await ellipse_multiFilterByUpdated(filters)
  }
  async function ellipse_multiFilterByUpdated(allFilters){
    //source data for calculation
    let attrs = attrData.value;
    
    //Abort if no attributes
    if(!attrs || !attrs.length>0) return
    
    let currentFilteredData = attrs

    if (memoizedList.value.length > 0){
      currentFilteredData = memoizedList.value[memoizedList.value.length -1].data
    }
    // Iterate over all filters starting from filterStartPointer
    for (let i = filterStartPointer.value; i< allFilters.length; i++){
      let currentFilter = allFilters[i]

      if (currentFilter.filter.filterType == "Search"){
        if (!isNullOrUndefined(currentFilter.filter.search)){
          let search = currentFilter.filter.search;
          let graphicData = filtering.searchByKeyword2(currentFilteredData, search)
          currentFilteredData = graphicData[0]
          memoizedList.value.push({widget: currentFilter.widget, filter: currentFilter.filter, data: graphicData[0]})
        }
      } 
      else if (currentFilter.filter.filterType == "Range"){
        let attribute = currentFilter.filter.field
        let range = currentFilter.filter.range
        let filterEmptyEntries = currentFilter.filter.filterEmptyEntries
        let graphicData = filtering.filterByRange2(currentFilteredData, attribute, range, filterEmptyEntries)
        currentFilteredData = graphicData[0]
        memoizedList.value.push({widget: currentFilter.widget, filter: currentFilter.filter, data: graphicData[0]})
      }
      else if (currentFilter.filter.filterType == "Value"){
        let values = currentFilter.filter.values
        let attribute = currentFilter.filter.field
        let graphicData = filtering.filterByValues2(currentFilteredData, values, attribute)
        currentFilteredData = graphicData[0]
        memoizedList.value.push({widget: currentFilter.widget, filter: currentFilter.filter, data: graphicData[0]})
      }
      else {
        let inRange = []
        if (currentFilter.filter.elements.length > 0){
          if (currentFilter.filter.isolate){
            inRange = currentFilteredData.filter(element => currentFilter.filter.elements.includes(element.ellipseId))
          } else {
            inRange = currentFilteredData.filter(element => !currentFilter.filter.elements.includes(element.ellipseId))
          }
          currentFilteredData = inRange
          memoizedList.value.push({widget: currentFilter.widget, filter: currentFilter.filter, data: inRange})
        }
      }
    }
    setFilterByData({graphicData: currentFilteredData})
    setFilteredEllipseIds(currentFilteredData)
  }
  async function removeAllFilters(){
    allFilters.value = []
    filterByData.value.graphicData = null
    setFilteredEllipseIds()
    memoizedList.value = []
  }
  async function addFilter(newFilter, remove = false){
    let doNothing = false
    let insertionIndex = null
    let isNewFilter = true

    const updateFilter = (index) => {
      allFilters.value[index] = JSON.parse(JSON.stringify(newFilter));
      insertionIndex = index;
      memoizedList.value = memoizedList.value.slice(0, insertionIndex);
    };
    
    const removeFilter = (index) => {
      insertionIndex = index;
      allFilters.value.splice(index, 1);
      memoizedList.value = memoizedList.value.slice(0, index);
    };

    allFilters.value.forEach((existingFilter, index) => {
      const isSameWidget = existingFilter.widget == newFilter.widget

      if (isSameWidget){
        let newFilterHasFieldProp = newFilter.filter.hasOwnProperty('field')
        let existingFilterHasFieldProp = existingFilter.filter.hasOwnProperty('field')
        let fieldsAreSame = (filter1, filter2) => filter1.filter.field == filter2.filter.field;

        if ((newFilterHasFieldProp && existingFilterHasFieldProp && fieldsAreSame(newFilter, existingFilter)) || 
          (!newFilterHasFieldProp && !existingFilterHasFieldProp)){
            isNewFilter = false
            if (remove) {
              removeFilter(index)
            } else {
              if (JSON.stringify(newFilter.filter) == JSON.stringify(existingFilter.filter)){
                doNothing = true
              }
              else {
                updateFilter(index)
              }
            }
        }
      }
    });
        
    //Check if filter is valid
    if (isNewFilter){
      if (!(newFilter.filter.search || newFilter.filter.field || newFilter.filter.elements)){
        doNothing = true
      }
    }
    if (doNothing) {
      return false
    }

    // AllFilters is an array with search filters listed first, then range, then value, and lastly element.
    // Calculate the number of each type of filter currently in allFilters 
    // to find the correct insertion index to maintain this order when adding a new filter.
    let numSearchFilters = allFilters.value.filter(filter => filter.filter.filterType == "Search").length
    let numRangeFilters = allFilters.value.filter(filter => filter.filter.filterType == "Range").length
    let numValueFilters = allFilters.value.filter(filter => filter.filter.filterType == "Value").length
    let numElementFilters = allFilters.value.filter(filter => filter.filter.filterType == "Element").length
    
    if (insertionIndex == null){
      let filterType = newFilter.filter.filterType
      if (filterType == "Search"){
        insertionIndex = numSearchFilters
      } 
      else if (filterType == "Range"){
        insertionIndex = numSearchFilters + numRangeFilters
      }
      else if (filterType == "Value"){
        insertionIndex = numSearchFilters + numRangeFilters + numValueFilters
      }
      else {
        insertionIndex = numSearchFilters + numRangeFilters + numValueFilters + numElementFilters
      }
      allFilters.value.splice(insertionIndex, 0, JSON.parse(JSON.stringify(newFilter)))
      memoizedList.value = memoizedList.value.slice(0, insertionIndex)
    }
    filterStartPointer.value = insertionIndex
    return true
  }

  return {
    attrData,
    attrHeaders,
    colorByData,
    labelByData,
    filterByData,

    getAttrData,
    getAttrHeaders,
    getAttrHeaderNames,
    getAttrHeaderOptions,
    getAttrHeadersCategorical,
    getAttrHeadersNumerical,
    getAttrByHeader,
    getUniqueAttrByHeader,
    getUniqueAttrTotalByHeader,
    getAttrByNumericalHeader,
    getUniqueAttrByNumericalHeader,
    getUniqueAttrTotalByNumericalHeader,
    getAttrByCategoricalHeader,
    getUniqueAttrByCategoricalHeader,
    getUniqueAttrTotalByCategoricalHeader,

    getColorByData,
    getLabelByData,
    getFilterByData,
    getAllFilters,

    getFilteredInEllipseIds,
    getFilteredOutEllipseIds,

    setAttrData,
    setFilteredEllipseIds,
    setAttrHeaders,
    setColorByData,
    setLabelByData,
    setFilterByData,

    addFilter,
    removeAllFilters,

    updateColorByData,
    updateColorByProperties,
    updateLabelByProperties,
    updateFilterByProperties,
  }
})