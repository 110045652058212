<template>
  <div class="widgetPadding pa-0">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="(updatingWidgets || getDatasetLoading) && notbookHasDrawings"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning
      :show="widgetIcon && grid_item.instance_setting.data && grid_item.instance_setting.data.assets.length === 0"
      :icon = messages.widgetEmpty.icon>{{ messages.widgetEmpty.memo }}{{lexicon.drawing.plural}}</NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

    <!-- UI Overlay -->
    <div class="dropdownStyle d-flex flex-row-reverse pr-4 pt-4">

      <!-- Drawing Selection Drop Down-->
      <div v-if="grid_item.instance_setting && grid_item.instance_setting.data.uiSettings.showModels">
        <template v-if="grid_item.instance_setting.data">
          <v-select 
            :items="drawings" 
            :item-title="selectedDrawingTitle" 
            :model-value="grid_item.instance_setting.data.selectedDrawing" 
            @update:modelValue="updateDrawing"
            :disabled="getEditMode" 
            hide-details 
            flat 
            rounded="xl"
            density="compact" 
            variant="solo" 
            item-value="_id" 
            placeholder="Select Drawing"
            return-object
          >
          </v-select>
        </template>
      </div>
    </div>

    <div class="d-flex align-self-center align-self-end zoomsStyle" v-if="grid_item.instance_setting">
      <ViewerControls :showZoom="grid_item.instance_setting.data.uiSettings.showZoom"
        :showFullScreen="grid_item.instance_setting.data.uiSettings.showFullscreen"
        :showMultiSelect="grid_item.instance_setting.data.uiSettings.showMultiSel"
        :showDownloads="grid_item.instance_setting.data.uiSettings.showDownloads"
        :showLayers="grid_item.instance_setting.layers && grid_item.instance_setting.data.uiSettings.showLayers"
        :layers="grid_item.instance_setting.layers && grid_item.instance_setting.layers.layers"
        v-on:zoomExtents="zoomExtents()" v-on:zoomSelected="zoomSelected()"
        v-on:zoomByPercentage="zoomByPercentage($event)" v-on:download="downloadSvg()"
        v-on:fullscreen="toggleFullScreen()" v-on:multiSelect="multiSelectClick()"
        v-on:layersUpdated="layersUpdated($event)" v-on:layersLocked="layersLocked($event)" />
    </div>
    <div ref="viewer2D" :id="svgId()" style="height: 100%; width:100%"></div>
  </div>
</template> 

<script>

import * as utils from "@/utilities"
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";

import filesaver from "file-saver";
import Spinner from "../../components/ui/Spinner.vue";
import NoDataWarning from "../../components/ui/NoDataWarning.vue";
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import { Viewer } from "@ttcorestudio/viewer_2d/library_files/Viewer.js";
import { FileLoader } from "@ttcorestudio/viewer_2d/library_files/FileLoader.js";
import { evaluate } from "@ttcorestudio/data-processor";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

import ViewerControls from "../viewerSettings/ViewerControls.vue";
let viewer2DInstances = {};
let fileLoaders = {};

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";
import { useEllipseStore } from "@/store/EllipseStore.js";

export default {
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
      getDatasetLoading
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getLabelByData,
      getFilterByData,
      getAttrData,
      getAttrHeaders,
      getAttrHeaderNames,
      getAttrHeadersCategorical,
      getFilteredInEllipseIds,
      getFilteredOutEllipseIds
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDrawings
    } = storeToRefs(assetsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      setHighlightedElements,
      addToSelectedElements,
      removeFromSelectedElements,
    } = notebookPropsStore

    return {
      assetsStore,
      dataGraphicsStore,
      notebookPropsStore,
      getEditMode,
      getDatasetLoading,
      getGlobalProperty,
      getHighlightedElements,
      getSelectedElements,
      getNotebook,
      getColorByData,
      getLabelByData,
      getFilterByData,
      getFilteredInEllipseIds,
      getFilteredOutEllipseIds,
      getAttrData,
      getAttrHeaders,
      getAttrHeaderNames,
      getAttrHeadersCategorical,
      getSelectedPageDrawings,
      setHighlightedElements,
      addToSelectedElements,
      removeFromSelectedElements,
      lexicon,
      messages,
    }
  },
  data() {
    return {
      updatingWidgets: false,
      isFullScreen: false,
      selectedColor: "#808080",
      colorByWidth: 0,
      mouseSensitivity: 1,
      labelSize: 12,
      showFullscreen: true,
      showDownloads: false,
      showLayers: true,
      showModels: true,
      showZoom: true,
      showMultiSel: false,
      multiSelectActive: false,
      emptyLayers: [{ name: 'none', isVisible: true, isLocked: false, opacity: 1, elemIds: [] }],
    };
  },
  components: {
    ViewerControls,
    Spinner,
    NoDataWarning,
    WidgetIcon,
    WidgetTitle
  },
  computed: {
    selectedDrawingTitle() {
      if (this.grid_item.instance_setting.data &&
        this.grid_item.instance_setting.data.selectedDrawing &&
        this.grid_item.instance_setting.data.selectedDrawing.cleanName) {
        return 'cleanName'
      }
      else return 'nickname'
    },
    instanceAssets() {
      if (this.grid_item.instance_setting && this.grid_item.instance_setting.data)
        return this.grid_item.instance_setting.data.assets
    },
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    drawings() {
      let drawings = this.grid_item.instance_setting.data.assets;
      let extendedDrawings = drawings.map(drawing => {
        drawing.cleanName = String(drawing.name);
        if (drawing.nickname) drawing.cleanName = drawing.nickname;
        return drawing
      })
      return extendedDrawings;
    },
    notbookHasDrawings() {
      if (this.drawings && this.drawings.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  async created() {
    this.verifyInstanceSettings();
  },
  async mounted() {
    this.verifyInstanceSettings();
    if (!this.grid_item._id || !this.grid_item.instance_setting.layers) this.setLayersKey();

    setTimeout(async () => {
      await this.creatingInstanceAndCanvas();
      await this.onNew2DReceived();
    })

    //Listen for changes to the notebookPropsStore
    this.notebookPropsStore.$onAction(({ name, args }) => {
      if (name === 'setHighlightedElements') {
        this.onNewElementHighlighted()
      }
      if (name === 'setGlobalHighlightColor') {
        this.globalHighlightColorEvent(args[0])
      }
      if (name === 'setGlobalSelectionColor') {
        this.globalSelectionColorEvent(args[0])
      }
      if (name === 'setGlobalSignificantDigits') {
        this.globalSignificantDigitsEvent(args[0])
      }
    })
    //Listen for changes to the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'updateColorByData') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateLabelByProperties') {
          this.onNewLabelByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
        if (name === 'removeAllFilters'){
          this.filtersRemoved()
        }
      })
    })
    this.assetsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdateEvent()
        }
      })
    })
  },
  beforeUnmount() {
    //this.removeEventListeners();
    if (viewer2DInstances[this.grid_item.i]) {
      delete viewer2DInstances[this.grid_item.i];
      delete fileLoaders[this.grid_item.i];
    }
  },
  watch: {
    instanceAssets: {
      deep: true,
      immediate: true,
      handler() {
        this.widgetAssetsChanged()
      }
    },
    "grid_item.instance_setting.data.colorByWidth": function () {
      if (viewer2DInstances[this.grid_item.i]) this.updateColorByParameters();
    },
    "grid_item.instance_setting.data.labelSize": function () {
      if (viewer2DInstances[this.grid_item.i]) viewer2DInstances[this.grid_item.i].updateAllLabels(this.grid_item.instance_setting.data.labelSize);
    },
    "grid_item.local_setting.foreground_color": function () {
      let backgroundColor = this.grid_item.local_setting.foreground_color;
      if (viewer2DInstances[this.grid_item.i]) viewer2DInstances[this.grid_item.i].setBackgroundColor(backgroundColor);
    },
    getSelectedElements: {
      deep: true,
      handler() {
        if (viewer2DInstances[this.grid_item.i]) {
          viewer2DInstances[this.grid_item.i].clear_pickSelection();
          if (this.getSelectedElements.length > 0) {
            viewer2DInstances[this.grid_item.i].selectElements(this.getSelectedElements, true)
          }
        }
      }
    }
  },
  methods: {
    verifyInstanceSettings() {

      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!this.grid_item.instance_setting.data.displaySettings) this.grid_item.instance_setting.data.displaySettings = {};
      if (!this.grid_item.instance_setting.data.colorByWidth) this.grid_item.instance_setting.data.colorByWidth = this.colorByWidth;
      if (!this.grid_item.instance_setting.data.mouseSensitivity) this.grid_item.instance_setting.data.mouseSensitivity = this.mouseSensitivity;
      if (!this.grid_item.instance_setting.data.labelSize) this.grid_item.instance_setting.data.labelSize = this.labelSize;

      if (!this.grid_item.instance_setting.data.uiSettings) this.grid_item.instance_setting.data.uiSettings = {};
      if (!('showFullscreen' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showFullscreen = this.showFullscreen;
      if (!('showDownloads' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showDownloads = this.showDownloads;
      if (!('showLayers' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showLayers = this.showLayers;
      if (!('showModels' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showModels = this.showModels;
      if (!('showZoom' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showZoom = this.showZoom;
      if (!('showMultiSel' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showMultiSel = this.showMultiSel;

      if (!('filteredHeaders' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filteredHeaders = this.getAttrHeaderNames;

      if (!('layers' in this.grid_item.instance_setting)) this.grid_item.instance_setting.layers = {};
      if (!('layers' in this.grid_item.instance_setting.layers)) this.grid_item.instance_setting.layers.layers = this.emptyLayers;

      if (!this.grid_item.instance_setting.data.assets) {
        this.grid_item.instance_setting.data.assets = JSON.parse(JSON.stringify(this.getSelectedPageDrawings))
        this.grid_item.instance_setting.data.selectedDrawing = this.grid_item.instance_setting.data.assets[0];
      }

      if (!this.grid_item.instance_setting.data.selectedDrawing) {
        this.grid_item.instance_setting.data.selectedDrawing = null
        if (this.grid_item.instance_setting.data.assets.length > 0) {
          this.grid_item.instance_setting.data.selectedDrawing = this.grid_item.instance_setting.data.assets[0];
        }
      }

      this.colorByWidth = this.grid_item.instance_setting.data.colorByWidth;
      this.mouseSensitivity = this.grid_item.instance_setting.data.mouseSensitivity;
      this.labelSize = this.grid_item.instance_setting.data.labelSize;
      this.showFullscreen = this.grid_item.instance_setting.data.uiSettings.showFullscreen;
      this.showDownloads = this.grid_item.instance_setting.data.uiSettings.showDownloads;
      this.showLayers = this.grid_item.instance_setting.data.uiSettings.showLayers;
      this.showModels = this.grid_item.instance_setting.data.uiSettings.showModels;
      this.showZoom = this.grid_item.instance_setting.data.uiSettings.showZoom;
      this.showMultiSel = this.grid_item.instance_setting.data.uiSettings.showMultiSel;

    },
    globalSignificantDigitsEvent(significant_digits) {
      if (viewer2DInstances[this.grid_item.i]) {
        viewer2DInstances[this.grid_item.i].digits = significant_digits;
      }
    },
    multiSelectClick() {
      if (this.multiSelectActive) {
        this.multiSelectActive = false;
        viewer2DInstances[this.grid_item.i].multiSelection(false);
        this.notebookPropsStore.$patch({ selectedElements: [] })
      } else {
        this.multiSelectActive = true;
        viewer2DInstances[this.grid_item.i].multiSelection(true);
      }
    },
    async widgetAssetsChanged() {
      // If selected drawing is not present in widget, change selected drawing to first drawing
      let selectedDrawingChanged = false;
      if (!this.grid_item.instance_setting.data) return;
      if (this.grid_item.instance_setting.data.selectedDrawing) {
        let selectedDrawingID = this.grid_item.instance_setting.data.selectedDrawing._id;
        let widgetDrawingIDs = this.grid_item.instance_setting.data.assets.map(drawings => drawings._id);

        if (!widgetDrawingIDs.includes(selectedDrawingID)) {
          // Selected drawing is no longer in the assets, reset to first asset or null
          if (this.grid_item.instance_setting.data.assets.length > 0) {
            this.grid_item.instance_setting.data.selectedDrawing = this.grid_item.instance_setting.data.assets[0];
          } else {
            this.grid_item.instance_setting.data.selectedDrawing = null;
          }
          selectedDrawingChanged = true;
        }
        // Else, no change is needed
      } else {
        // If no selectedDrawing is set, set it to the first asset if available
        if (this.grid_item.instance_setting.data.assets.length > 0) {
          this.grid_item.instance_setting.data.selectedDrawing = this.grid_item.instance_setting.data.assets[0];
          selectedDrawingChanged = true;
        }
      }

      if (selectedDrawingChanged) {
        if (viewer2DInstances[this.grid_item.i]) {
          viewer2DInstances[this.grid_item.i].clearAll();
          this.updateWidget();
          await this.creatingInstanceAndCanvas();
          await this.onNew2DReceived();
        }
      }
    },
    svgId() {
      return "id" + this.grid_item.i;
    },
    updateColorByParameters() {
      viewer2DInstances[this.grid_item.i].updateLineWidth(this.grid_item.instance_setting.data.colorByWidth);
    },
    datasetUpdateEvent() {
      this.setLayersKey();
      if (!this.grid_item.instance_setting.layers) return;
      let key = this.grid_item.instance_setting.layers.layerBy;
      let elemVals = [];
      this.getAttrData.forEach((attr) => {
        elemVals.push(attr[key.name]);
      });

      let tempVal = evaluate.getUniqueVals(elemVals);
      this.grid_item.instance_setting.layers.layers = tempVal.map(
        (item) => {
          const layer = {};

          layer.name = item;
          layer.isVisible = true;
          layer.isLocked = false;
          layer.opacity = 1;

          layer.elemIds = this.getAttrData.reduce(function (acc, att) {
            if (att.hasOwnProperty(key.name) && att[key.name] === item)
              acc.push(att.ellipseId);
            return acc;
          }, []);

          return layer;
        }
      );
    },
    // Layers Settings Methods -
    layersUpdated: function (updated) {
      if (!viewer2DInstances[this.grid_item.i]) return;
      this.grid_item.instance_setting.layers.layers = updated.layers;
      
      //updates the index layer
      viewer2DInstances[this.grid_item.i].showElements(
        updated.layers[updated.index].elemIds,
        updated.layers[updated.index].isVisible
      );
      // this.updateWidget();
    },
    // Layers Settings Methods -
    layersLocked: function (updated) {
      if (!viewer2DInstances[this.grid_item.i]) return;
      this.grid_item.instance_setting.layers.layers = updated.layers;
      //lock the index layer
      viewer2DInstances[this.grid_item.i].lockElements(
        updated.layers[updated.index].elemIds,
        updated.layers[updated.index].isLocked
      );
      // this.updateWidget();
    },
    setLayersKey(newKeyName = "Category") {
      let key;
      if (this.getAttrHeaders.length === 0) {
        this.grid_item.instance_setting.layers = undefined;
        return;
      }
      if (this.grid_item.instance_setting.layers === undefined) {
        //Set Category Header as default Layer Headder;
        key = this.getAttrHeaders.filter((h) => h.name === newKeyName)[0];

        //If there is a Header called 'Layer' and its not empty, use that instead of 'category'
        let defaultLayer = this.getAttrHeaders.filter((h) => h.name === "Layer");
        if (defaultLayer.length !== 0) key = defaultLayer[0];
        //Build Layers Object
        this.grid_item.instance_setting.layers = {
          layerBy: key,
          layers: [],
        };
        //Populate getSelectedWidget.instance_setting.layers.layers
      } else {
        key = this.getAttrHeaders.filter((h) => h.name === newKeyName)[0];
        this.grid_item.instance_setting.layers.layerBy = key;
      }
      this.setLayers(key);
    },
    setLayers(key) {
      // set the layers object get unique values and add isVisible:, isLocked, opacity values:
      //let key = this.getSelectedWidget.instance_setting.layers.layerBy;
      if (key) {
        let elemVals = [];
        this.getAttrData.forEach((attr) => {
          elemVals.push(attr[key.name]);
        });

        let tempVal = evaluate.getUniqueVals(elemVals);

        this.grid_item.instance_setting.layers.layers = tempVal.map(
          (item) => {
            const layer = {};
            layer.name = item;
            layer.isVisible = true;
            layer.isLocked = false;
            layer.opacity = 1;
            layer.elemIds = this.getAttrData.reduce(function (acc, att) {
              if (att.hasOwnProperty(key.name) && att[key.name] === item)
                acc.push(att.ellipseId);
              return acc;
            }, []);

            return layer;
          }
        );
      }
    },
    // Zoom Options Methods -
    zoomByPercentage: function (percentage) {
      if (this.grid_item.instance_setting.data && this.grid_item.instance_setting.data.assets.length === 0) return
      viewer2DInstances[this.grid_item.i].multipleModelPosition(percentage / 100);
    },
    zoomExtents: function () {
      viewer2DInstances[this.grid_item.i].zoomExtents();
    },
    zoomSelected: function () {
      if (this.getSelectedElements.length > 0)
        viewer2DInstances[this.grid_item.i].zoomSelection(this.getSelectedElements[0]);
    },
    async creatingInstanceAndCanvas() {
      let canvas = this.$refs.viewer2D;
      let selectionColor =
        this.getGlobalProperty.global_setting.selection_color;
      let highlightColor =
        this.getGlobalProperty.global_setting.highlight_color;
      let strokeWidth = this.grid_item.instance_setting.data.colorByWidth;
      let mouseSensitivity = this.grid_item.instance_setting.data.mouseSensitivity;
      let labelSize = this.grid_item.instance_setting.data.labelSize;
      viewer2DInstances[this.grid_item.i] = new Viewer({
        backgroundColor: this.grid_item.local_setting.foreground_color,
        pickColor: selectionColor,
        highlightColor: highlightColor,
        strokeWidth: strokeWidth,
        hoverWidth: mouseSensitivity,
        labelSize: labelSize,
        digits: this.getGlobalProperty.global_setting.significant_digits,
        tooltipColor: this.$vuetify.theme.current.colors.darkSlate,
      });
      viewer2DInstances[this.grid_item.i].pickColor = selectionColor; // delete after params.pickColor init work
      viewer2DInstances[this.grid_item.i].addViewer(canvas);

      viewer2DInstances[this.grid_item.i].multiSelection(false);
      viewer2DInstances[this.grid_item.i].highlightCheck(true);

      viewer2DInstances[this.grid_item.i].registerEvent("element-select", this.elementSelectEvent);

      viewer2DInstances[this.grid_item.i].registerEvent("element-highlight", (e) => {
        if (e == null) {
          this.clearAllHighlights();
        }
        if (e === "2D_viewer") return;
        this.setHighlightedElements([e])
        this.setTooltipContent();
      }
      );
    },
    async updateDrawing(selectedDrawing) {
      this.updatingWidgets = true;
      this.grid_item.instance_setting.data.selectedDrawing = selectedDrawing;
      viewer2DInstances[this.grid_item.i].clearAll();
      await this.onNew2DReceived();
      // this.updateWidget();
      this.updatingWidgets = false;
    },
    async onNew2DReceived() {
      //is this the best place for a if statment like this?
      if (this.grid_item.instance_setting.data.selectedDrawing == null) return
      if (!this.grid_item.instance_setting.data.selectedDrawing.s3_pointers) return
      this.updatingWidgets = true;

      fileLoaders[this.grid_item.i] = new FileLoader(viewer2DInstances[this.grid_item.i]);

      // ! Outdated and slower 
      // let svgData = await this.$auth.$api.post(
      //   `/api/drawings/load-drawing-data-for-notebook/?urlType=${this.$route.name}`,
      //   {
      //     s3_key:
      //       this.grid_item.instance_setting.data.selectedDrawing.s3_pointers[0],
      //     notebookId: this.getNotebook._id,
      //   }
      // );
      // let data2D = JSON.parse(svgData.data.data.drawing2D_data).data;

      // let blb = new Blob([data2D], {
      //   type: "text/plain",
      // });

      //! This way we dont sent data thru backend ! get signed url for 2D Drawing
      console.log("onNew2DReceived")
      let signed_url = await this.$auth.$api.post(
        `/api/notebook/${this.getNotebook._id}/file/get-s3-signed-urls/?urlType=${this.$route.name}`,
        {
          key: this.grid_item.instance_setting.data.selectedDrawing.s3_pointers[0]
        }
      );

      const response = await fetch(signed_url.data.url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blb = await response.blob();
      //console.log("Drawing received")

      fileLoaders[this.grid_item.i].addModel([blb]);
      fileLoaders[this.grid_item.i].addEventListener("load-finished", (e) => {
        //sticky Vis
        if (this.getColorByData.graphicData) this.onNewColorByUpdated();
        if (this.getLabelByData.graphicData) this.onNewLabelByUpdated();
        if (this.getFilterByData.graphicData) this.onNewFilterByUpdated();
        this.updatingWidgets = false;
      });
    },
    elementSelectEvent(ellipseIds) {
      //set selection to null if background clicked and an element is already selected
      var ellipseId = ellipseIds[0];

      if (this.multiSelectActive) {
        if (this.getSelectedElements.includes(ellipseId)) {
          this.removeFromSelectedElements(ellipseId)
        } else {
          this.addToSelectedElements(ellipseId)
        }
        if (ellipseId === undefined) {
          this.notebookPropsStore.$patch({ selectedElements: [] })
        }
      } else {
        if (ellipseId === "" || ellipseId === undefined) {
          this.notebookPropsStore.$patch({ selectedElements: [] })
        }
        else {
          this.notebookPropsStore.$patch({ selectedElements: [ellipseId] })
        }
      }
    },
    getHighlightedElementsAttributes() {
      let idSet = new Set(this.getHighlightedElements);
      // Filter the based on the presence of the ID in the Set
      return this.getAttrData.filter((obj) => idSet.has(obj.ellipseId));
    },
    setTooltipContent() {
      if (viewer2DInstances[this.grid_item.i]) {
        let attributes =this.getHighlightedElementsAttributes();
        if (attributes) {
          let results = utils.getColorByLabel(attributes, this.getColorByData.graphicData, this.grid_item.instance_setting.data.filteredHeaders.map((x) => x), this.getGlobalProperty.global_setting.color_by_attribute);
          let values = results.label;
          
          values = values + utils.tabulatedAttributeToList(utils.tabulateAttributes(attributes, this.getAttrHeaders, 'variable'), results.headers);
          viewer2DInstances[this.grid_item.i].setTooltipContent(values);
        }
      }
    },
    async onNewElementHighlighted() {
      if (viewer2DInstances[this.grid_item.i]) {
        viewer2DInstances[this.grid_item.i].clear_highlightSelection();
        if (this.getHighlightedElements) {
          viewer2DInstances[this.grid_item.i].highlightedElements(this.getHighlightedElements);
        }
      }
    },
    async onNewColorByUpdated() {
      let opts = this.getColorByData.graphicData;
      if (viewer2DInstances[this.grid_item.i] && viewer2DInstances[this.grid_item.i].canvas) {
        viewer2DInstances[this.grid_item.i].resetAllColors();
        if (opts) {
          viewer2DInstances[this.grid_item.i].colorElements(opts.ids, opts.colors, false);
          viewer2DInstances[this.grid_item.i].setOpacity(opts.ids_inValidValue, 0.4);
        }
      }
    },
    async onNewLabelByUpdated() {
      let opts = this.getLabelByData.graphicData;
      if (viewer2DInstances[this.grid_item.i] && viewer2DInstances[this.grid_item.i].canvas) {
        if (this.getLabelByData.attribute == "None") {
          viewer2DInstances[this.grid_item.i].clearAllLabels();
          return;
        }
        if (opts) {
          viewer2DInstances[this.grid_item.i].labelElements(opts.ids, opts.values);
        }
      }
    },
    async onNewFilterByUpdated() {
      let elementsToHide = this.getFilteredOutEllipseIds ? this.getFilteredOutEllipseIds : this.getAttrData.map(data => data.ellipseId)

      if (viewer2DInstances[this.grid_item.i] && viewer2DInstances[this.grid_item.i].canvas) {
        viewer2DInstances[this.grid_item.i].showAll(true);
        viewer2DInstances[this.grid_item.i].showElements(elementsToHide, false);
      }
    },
    filtersRemoved(){
      viewer2DInstances[this.grid_item.i].showAll(true);
    },
    async globalHighlightColorEvent(highlight_color) {
      if (viewer2DInstances[this.grid_item.i] && viewer2DInstances[this.grid_item.i].canvas) {
        viewer2DInstances[this.grid_item.i].highlightColor = highlight_color;
      }
    },
    async globalSelectionColorEvent(selection_color) {
      if (viewer2DInstances[this.grid_item.i] && viewer2DInstances[this.grid_item.i].canvas) {
        viewer2DInstances[this.grid_item.i].pickColor = selection_color;
      }
    },
    async clearAllHighlights() {
      if (viewer2DInstances[this.grid_item.i] && viewer2DInstances[this.grid_item.i].canvas) {
        viewer2DInstances[this.grid_item.i].clear_highlightSelection();
      };
    },
    async clearHighlights() {
      viewer2DInstances[this.grid_item.i].clear_highlightSelection();
    },
    async updateWidget() {
      let postData = {
        widgets: [this.grid_item],
      };

      let update_widgets = await this.$auth.$api.post(
        `/api/widget/update_widgets/?urlType=${this.$route.name}`,
        postData
      );
    },
    toggleFullScreen() {
      if (this.isFullscreen) {
        this.closeFullscreen();
      }
      else {
        this.openFullscreen();
      }
    },
    toggleFullScreen() {
      if (this.isFullscreen) {
        this.closeFullscreen();
      }
      else {
        this.openFullscreen();
      }
    },
    openFullscreen() {
      const elem = document.getElementById(this.grid_item._id);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
      this.isFullScreen = false;
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      this.isFullScreen = true;
    },
    downloadSvg() {
      var svg = viewer2DInstances[this.grid_item.i].toSvgString();
      const blob = new Blob([svg], {
        type: 'image/svg+xml' // or whatever your Content-Type is
      });
      filesaver.saveAs(blob, this.getNotebook.name + "-" + this.selectedPage.name + "-" + this.grid_item.instance_setting.data.selectedDrawing.name.replace(/\.[^/.]+$/, "")+ "-" + utils.timeStamp()+".svg");
    },
  },
};
</script>

<style scoped>
.v-input:deep(.v-select__selection) {
  display: flex;
  align-items: center;
}

.v-input:deep(.v-select__selection-text) {
  font-size: 14px !important;
  width: 170px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px
}
</style>
