<template>
  <v-container>
    <!-- zoom options -->
    <v-btn-group color="background" id="view-toggle" rounded="xl" variant="elevated" density="compact">

      <v-btn v-if="showZoom" size="small" class="px-4" icon>
        <v-icon>{{ icons.zoomPercentage }}</v-icon>
        <v-tooltip activator="parent" attach="parent" location="top">
          <span>Zoom Percentage</span>
        </v-tooltip>

        <v-menu 
          left 
          bottom 
          activator="parent"
          attach="parent"
        >
          <v-list>
            <v-list-item v-for="n in 8" :key="n" @click="zoomByPercentage(n)">
              <v-list-item-title>{{ n * 25 }} %</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>

      <v-btn v-if="showZoom" size="small" class="px-4" icon @click="zoomSelected">
        <v-icon>{{ icons.zoomSelected }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="parent">
          <span>Zoom Selected</span>
        </v-tooltip>
      </v-btn>

      <v-btn v-if="showZoom" size="small" class="px-4" icon @click="zoomExtents">
        <v-icon>{{ icons.zoomExtents }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="parent">
          <span>Zoom Extents</span>
        </v-tooltip>
      </v-btn>

        <v-divider v-if="showFullScreen" vertical></v-divider>
      <v-btn v-if="showFullScreen" size="small" class="px-4" icon @click="fullscreen">
        <v-icon>{{ icons.fullScreen }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="parent">
          <span>FullScreen</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showMultiSelect" vertical></v-divider>
      <v-btn v-if="showMultiSelect" size="small" class="px-4" icon @click="multiSelect">
        <v-icon :icon="multiSelection ? 'mdi:mdi-checkbox-multiple-marked-circle-outline' : 'mdi:mdi-checkbox-marked-circle-outline'"></v-icon>
        <v-tooltip activator="parent" location="top" attach="parent">
          <span>Toggle Multi Select</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showDownloads" vertical></v-divider>
      <v-btn v-if="showDownloads" size="small" class="px-4" icon @click="download">
        <v-icon icon="mdi:mdi-download-circle-outline"></v-icon>
        <v-tooltip activator="parent" location="top" attach="parent">
          <span>{{ lexicon.download}}</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showCameras" vertical></v-divider>
      <v-btn v-if="showCameras" size="small" class="px-4" icon >
        <v-icon>{{ icons.setView}}</v-icon>
        <CameraMenuList
        :isPerspective="cameraProjection"
        :customCameras="customCameras"
          v-on:cameraUpdated="cameraUpdated($event)"
          v-on:camerasUpdated="camerasUpdated($event)"  
          v-on:cameraSaved="cameraSaved($event)"  
          v-on:projectionUpdated="cameraProjectionUpdated($event)"  
          />
      </v-btn>

      <v-divider v-if="showLayers" vertical></v-divider>
      <v-btn v-if="showLayers" size="small" class="px-4" icon>
        <v-icon>{{ icons.layer }}</v-icon>
        <LayerMenuList
          :layers="layers" 
          v-on:layersUpdated="layersUpdated($event)"
            v-on:layersLocked="layersLocked($event)" 
        />
      </v-btn>

      <v-divider v-if="showModels" vertical></v-divider>
      <v-btn v-if="showModels" size="small" class="pl-4 pr-5" icon>
        <v-icon>{{ lexicon.model.icon }}</v-icon>
        <AssetMenuList
          :models="models" 
          v-on:modelUpdated="modelUpdated($event)"
        />
      </v-btn>

    </v-btn-group>
  </v-container>
</template>

<script>
import AssetMenuList from "@/components/viewerSettings/AssetMenuList.vue";
import LayerMenuList from "@/components/viewerSettings/LayerMenuList.vue";
import CameraMenuList from "@/components/viewerSettings/CameraMenuList.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  setup() {
    return {icons, lexicon}
  },
  props: ["showFullScreen", "showZoom", "showDownloads", "showMultiSelect", "showLayers", "showModels", "layers", "customCameras", "cameraProjection", "multiSelection", "models", "showCameras"],
  data() {
    return {
    }
  },
  methods: {
    zoomByPercentage(n) {
      this.$emit("zoomByPercentage", n * 25);
    },
    zoomExtents() {
      this.$emit("zoomExtents", {});
    },
    zoomSelected() {
      this.$emit("zoomSelected", {});
    },
    download() {
      this.$emit("download", {});
    },
    fullscreen() {
      this.$emit("fullscreen", {});
    },
    multiSelect() {
      this.isMultiSelect = ! this.isMultiSelect;
      this.$emit("multiSelect", {});
    },
    layersUpdated(e) {
      this.$emit("layersUpdated", e);
    },
    layersLocked(e) {
      this.$emit("layersLocked", e);
    },
    modelUpdated(e) {
      this.$emit("modelUpdated", e);
    },
    cameraUpdated(e) {
      this.$emit("cameraUpdated", e);
    },
    cameraSaved(e){
      this.$emit("cameraSaved", e);
    },
    camerasUpdated(e){
      this.$emit("camerasUpdated", e);
    },
    cameraProjectionUpdated(e){
      this.$emit("cameraProjectionUpdated", e);
    },
  },
  components: {
    LayerMenuList, CameraMenuList,AssetMenuList,
  },
};
</script>

<style scoped>

</style>