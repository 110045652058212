/**
 * provides an array of valid file column headers
 * for use with file attachments
 */
const validFileHeaders = [
  '_file',
  'file',
  '_files',
  'files',
];


/**
 * provides an array of valid image column headers
 * for use with image attachments
 */
const validImageHeaders = [
  '_image',
  'image',
  '_images',
  'images',
  '_img',
  'img',
  '_imgs',
  'imgs',
];

const validIdHeaders = [
  'ellipseid', 
  '_ellipseid', 
  'id', 
  '_id', 
  'ids', 
  '_ids', 
  'uuid', 
  'guid', 
  'name',
];

/**
 * Checks if the given JSON dataset includes
 * headers within the valid Header Array
 * and returns an array of valid keys.
 * @param {*} jsonData 
 * @param {*} validHeaders 
 * @returns {Array} Array of valid keys
 */
const findValidHeaderKeys = (jsonData, validHeaders) =>{
  const validKeys = new Set();
  
  for (const object of jsonData) {
    
    for (const key of Object.keys(object)) {

      if ( validHeaders.includes(key) 
           || validHeaders.some(header => key.startsWith(header+'-') ) ) 
      {
        validKeys.add(key);
      }
    }
  }

  //convert set to array
  return Array.from(validKeys);
};

/**
 * Checks if the given header is valid
 * @param {*} header 
 * @param {*} validHeaders 
 * @returns {Boolean} Returns true if header is valid
 */
const isHeaderValid = (header, validHeaders) => {
  return validHeaders.includes(header) 
    || validHeaders.some(validHeader => header.startsWith(validHeader));
};

/**
 * Checks if the given header starts with a valid header followed by a '-'.
 * @param {string} header 
 * @param {Array} validHeaders 
 * @returns {Boolean} Returns true if the header is valid and followed by a '-'.
 */
const isHeaderValidWithDash = (header, validHeaders) => {
  return validHeaders.some(validHeader => header.startsWith(validHeader + '-'));
};

/**
 * Extracts the portion of the string after the '-'.
 * @param {string} header 
 * @param {Array} validHeaders 
 * @returns {string} The portion of the string after the '-'.
 */
const extractAfterDash = (header, validHeaders) => {
  for (const validHeader of validHeaders) {
    if (header.startsWith(validHeader + '-')) {
      return header.substring(validHeader.length + 1);
    }
  }
  return null;
};

export {
  validFileHeaders,
  validImageHeaders,
  validIdHeaders,
  findValidHeaderKeys,
  isHeaderValidWithDash,
  isHeaderValid,
};
