<template>
    <div class="widgetPadding pa-0">
      <!-- Corner Icons for View and Edit Mode -->
      <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
      <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>
      <v-sheet style="width:100%; height:100%;" ></v-sheet>
    </div>
</template>
  
<script>
  import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
  import WidgetIcon from "../ui/WidgetIcon.vue";
  import WidgetTitle from "../../components/ui/WidgetTitle.vue";
  import NoDataWarning from "../ui/NoDataWarning.vue";
  
  import { storeToRefs } from 'pinia';
  import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

  export default {
    components: {WidgetIcon, NoDataWarning,WidgetTitle},
    props: ["grid_item"],
    setup() {
      const notebookPropsStore = useNotebookPropsStore()
      const {
        getEditMode,
        getGlobalProperty
      } = storeToRefs(notebookPropsStore)
      const {
      } = notebookPropsStore
      return {getEditMode, getGlobalProperty}
    },
    data() {
      return {
      };
    },
    created(){
      this.verifyInstanceSettings();
    },
    computed: {
      widgetIcon() {
        let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
        return result;
      },
    },
    mounted() {
      this.verifyInstanceSettings();
    },
    methods:{
      verifyInstanceSettings() {
        
      },
    }
  };
  </script>
  <style scoped>

  </style>
  