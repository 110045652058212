<template>
  <div class="widgetPadding pa-0" style="height:100%"> 
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

        <!-- Header Description -->
        <v-container class="px-2 pt-2 pb-0 d-flex flex-row-reverse">

            <h5 class="WidgetSubHeader pr-1">
                       
                <small>
                <strong>
                  {{ getSelectedElements.length }}
                </strong>
                Elements Selected 
            </small>
            </h5>

        </v-container>

    <!-- Primary Content -->
      <!-- Status Count -->
        <v-container class="px-2 pt-2 pb-0" style="overflow-y: auto;">
          <AttributesList :variable="variableAttributesMsg"></AttributesList>
        </v-container>
    </div>
</template>
  
<script>

import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import AttributesList from "../ui/AttributesList.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

export default {
  components: { WidgetIcon, WidgetTitle, AttributesList },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getEditMode,
      getSelectedElements
    } = storeToRefs(notebookPropsStore)
    return {
      getEditMode, 
      getSelectedElements, 
    }
  },
  data: () => ({
    datasetSwitchingLoading: false,
    truncateData: false,
    variableAttributesMsg: "varies",
  }),
  created() {
    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {

    },
  },
};
</script>

<style scoped>

</style>
  