<template>
  <div class="widgetPadding pt-0">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

		<div v-if="getAttrData.length > 0 && grid_item.instance_setting.data" class="flexColumn">
			<!-- Field Selection -->
			<div v-if="grid_item.instance_setting.data.filterValues" style="overflow-y: auto;" class="ma-0 pa-0">

				<template v-if="grid_item.instance_setting.data.filterValues.length">
					<v-combobox 
            item-title="formattedValue" 
            v-model="grid_item.instance_setting.data.selectedValue" 
            :items="filterValuesComputed"
            :label="this.grid_item.instance_setting.data.selectedAttribute"
            @update:modelValue="toggleFilterValue()" 
            variant="outlined" class="ma-0 px-2 pb-0" style="margin-top: 10px !important">
					</v-combobox>
				</template>
			</div>

		</div>
	</div>
</template>

<script>
import * as utils from "@/utilities";
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

export default {
	components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle},
	props: ["grid_item"],
	setup() {
		const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
		const {
			getEditMode,
      getDatasetLoading
		} = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
			getAttrHeaderOptions
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
		const {
      updateFilterByProperties,
      addFilter
		} = dataGraphicsStore
		return {assetsStore, dataGraphicsStore,
    getEditMode, getAttrData, getAttrHeaderOptions, getSelectedPageDatasets, getDatasetLoading,
    addFilter, updateFilterByProperties}
	},
	data() {
		return {
			filterType: "Value",
			//Headder for dropdown
			selectedAttribute: "None",
			//List of values for dropdown
			filterValues: [{ value: "None", show: true }],
		};
	},
	created() {
		this.verifyInstanceSettings();
	},
	mounted() {
		this.verifyInstanceSettings();
		this.selectedAttributeCheck();
		this.filterByValues();
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdate()
        }
      })
    })
		this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "removeAllFilters"){
          this.resetFilter()
        }
      })
    })
	},
	watch: {
    "grid_item.instance_setting.data.selectedAttribute": function() {
      this.propsUpdatedEvent()
    },
	},
	computed: {
		widgetIcon() {
			let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
			return result;
		},
		filterValuesComputed() {
			let arrValues = this.grid_item.instance_setting.data.filterValues;
			for (let i = 0; i < arrValues.length; i++) {
				arrValues[i] = {
          value: arrValues[i].value,
          formattedValue: this.formatValue(arrValues[i].value)
        }
			}
			return arrValues;
		},
	},
	methods: {
		verifyInstanceSettings() {
			if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

			if (!this.grid_item.instance_setting.data.selectedAttribute) this.grid_item.instance_setting.data.selectedAttribute = this.selectedAttribute;
			if (!this.grid_item.instance_setting.data.filterValues) this.grid_item.instance_setting.data.filterValues = this.filterValues;
			if (!this.grid_item.instance_setting.data.filterType) this.grid_item.instance_setting.data.filterType = this.filterType;

			// Initialize selectedValue to the value property instead of the whole object
			if (!this.grid_item.instance_setting.data.selectedValue) {
				this.grid_item.instance_setting.data.selectedValue = "None";
			}
		},
		propsUpdatedEvent() {
			this.selectedAttributeUpdate();
			this.filterByValues();
		},
		datasetUpdate() {
			this.selectedAttributeUpdate();
		},
		formatValue(data) {
			return utils.setDigits(data);
		},
		toggleFilterValue(x) {
			this.updateFilterValues();
			this.filterByValues();
		},
		selectedAttributeCheck() {
			if (this.grid_item.instance_setting.data.selectedAttribute) {
				let result = this.getAttrHeaderOptions.filter(a => a === this.grid_item.instance_setting.data.selectedAttribute);
				if (result.length === 0) {
					this.grid_item.instance_setting.data.selectedAttribute = this.getAttrHeaderOptions[0];
					this.grid_item.instance_setting.data.filterValues = [];
					this.grid_item.instance_setting.filters = [];
				}
			}
		},
		selectedAttributeUpdate() {
			this.selectedAttributeCheck();
			let selectedField =
				this.grid_item.instance_setting.data.selectedAttribute;
			this.grid_item.instance_setting.data.filterValues =
				this.createFilterValues(selectedField);
		},
		createFilterValues(selectedField) {
			let newFilterValues = [];
			if (selectedField !== "None") {
				let fieldValues = [];
				this.getAttrData.forEach((attr) => {
					fieldValues.push(attr[selectedField]);
				});

				let uniqueFieldValues = evaluate.getUniqueVals(fieldValues);

				newFilterValues = uniqueFieldValues.map((item) => {
					const filter = {};
					filter.value = item;
					filter.show = true;
					return filter;
				});

				newFilterValues.pop();//Remove last entry (its incomplete)
				newFilterValues.unshift({ value: "None", show: true });//Append new None Option
				this.grid_item.instance_setting.data.selectedValue = newFilterValues[0].value;
				this.grid_item.instance_setting.data.filterValues = newFilterValues;
				return newFilterValues;
			}
			this.filterByValues();
		},
		removeNoneValue() {
			let filterValues = this.grid_item.instance_setting.data.filterValues;
			let newFilterValues = filterValues.filter((item) => {
				return item.value !== "None";
			});
			return newFilterValues;
		},
		updateFilterValues() {
			let selectedValue = this.grid_item.instance_setting.data.selectedValue
			let filterValues = this.grid_item.instance_setting.data.filterValues
			let status = (selectedValue.value == "None") ? true : false;
			let newFilterValues = filterValues.map((item) => {
				if (item.value === selectedValue.value) {
					item.show = true;
				}
				else {
					item.show = status;
				}
				return item;
			});

			this.grid_item.instance_setting.data.filterValues = newFilterValues;
		},
		async filterByValues() {
      let updateGlobalFilters
			if (this.grid_item.instance_setting.data.selectedAttribute !== "None") {
				let filterObject = {
					filterType: "Value",
					field: this.grid_item.instance_setting.data.selectedAttribute,
					values: this.removeNoneValue(),
				};
				this.grid_item.instance_setting.filters = [filterObject];
				
				if ((filterObject.values.length > 0) && this.grid_item.instance_setting.data.selectedAttribute != null) {
					updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
				} else {
					this.grid_item.instance_setting.filters = [];
          updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: {filterType: "Value"}}, true)
				}
			} else {
				this.grid_item.instance_setting.filters = [];
        updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: {filterType: "Value"}}, true)
			}
      if (updateGlobalFilters) await this.updateFilterByProperties()
		},
		resetFilter(){
			this.grid_item.instance_setting.data.selectedValue = { value: "None", show: true }
			this.updateFilterValues()
		}
	},
	async unmounted() {
	},
};
</script>

<style scoped>
	.v-input{
			padding:0px !important;
	}
</style>