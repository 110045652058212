<template>
  <tr @click="$emit('onNotebookClick')" style="cursor: pointer; font-size:14px">
    <td>
      <v-avatar class="rounded-lg" height="32">
        <v-img src="../assets/imgs/notebookCardPlaceholder.png"></v-img>
      </v-avatar>
    </td>
    <td><h4>{{ notebook.name }}</h4> </td>
    <td>
      <span><Sharing :notebook="notebook" /></span>
    </td>
    <td >{{ notebook.createdBy.user.name }} </td>
    <td>{{ $filters.momentTimestampDDMMYYYY(notebook.createdAt) }} </td>
    <td class="text-right">
            <span>
              <v-menu offset-y>
                <template v-slot:activator="{ props}">
                  <v-btn v-bind="props" icon size="x-small" variant="text" >
                    <v-icon size="small" color="darkSlate">{{ icons.menu }}</v-icon>
                  </v-btn
                  >
                </template>
                <div>
                  <v-card
                    class="mx-auto"
                    max-width="250"
                  >
                    <v-list density="compact">
                      <v-list-item
                        v-for="(item, i) in options"
                        :key="i"
                        :value="item"
                        @click="$emit(item.action)"
                      >
                        <template v-slot:prepend>
                        <v-icon :icon="item.icon"></v-icon>
                        </template>
                        <v-list-item-title>{{item.text}}</v-list-item-title>
                      </v-list-item>
                      <Sharing :notebook="notebook" :itemtype="'list'"/>
                    </v-list>
                  </v-card>
                </div>
              </v-menu>
            </span>
    </td>

  </tr>
</template>

<script>
import Sharing from "./popUps/NotebookCardSharing.vue";
import AreYourSurePopUp from "../components/popUps/AreYouSure.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: { Sharing, AreYourSurePopUp},
  props: ["notebook"],
  setup(){
    const ellipseStore = useEllipseStore();
    const { 
      getNotebooks 
    } = storeToRefs(ellipseStore);
    const {
      setNotebooks,
      setTemplates
    } = ellipseStore;
    return { getNotebooks,
    setNotebooks, setTemplates, lexicon, messages, icons };
  },
  data() {
    return {
      options: [
        {text: lexicon.rename , icon: icons.rename , action: 'onEditNotebookClick'},
        {text: lexicon.duplicate , icon: icons.duplicate, action: 'OnCopyNotebookClick'},
        {text: lexicon.del , icon: icons.remove , action: 'onDeleteNotebookClick'},
        {text: messages.templateSave , icon: lexicon.template.icon , action: 'onCreateTemplateClick'},
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
  .notebook-property{
    font-size: 12px;
    padding-left: 4px;
    font-weight: 300;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .notebook-property-div{
    padding-top: 4px;
    display: flex;
  }
</style>
