import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getInstance } from "../auth/index";
import router from "@/router";

export const useNotebookPropsStore = defineStore('notebookProps', () => {

  //State Variables
  const editMode = ref(false)
  const presave = ref(false)
  const highlighedElement = ref('')
  const globalPropertyUpdated = ref(false)
  const globalProperty = ref({
    global_setting: {
      color_by_attribute: null,
      color_by_gradient: null,
      color_by_range: [],
    },
  })
  const selectedElements = ref([])
  const selectedNavigationTab = ref("globalProperties")
  const selectedWidget = ref(null)
  const widgets = ref([])
  const widgetIDsToUpdate = ref([])
  const widgetsToDelete = ref([])

  const allPageStates = ref([])
  const pagesStructuredData = ref([])
  const selectedPage = ref({})
  const selectedPageStateId = ref("")

  const loadingScreen = ref(false)
  const datasetLoading = ref(false)

  //Getters
  const getEditMode = computed(() => editMode.value)
  const getPresave = computed(() => presave.value)
  const getGlobalPropertyUpdated = computed(() => globalPropertyUpdated.value)
  const getGlobalProperty = computed(() => globalProperty.value)
  const getGlobalSignificantDigits = computed(() => globalProperty.value.global_setting.significant_digits)
  const getGlobalColorByAttribute = computed(() => globalProperty.value.global_setting.color_by_attribute)
  const getGlobalColorByGradient = computed(() => globalProperty.value.global_setting.color_by_gradient)
  const getGlobalColorByRange = computed(() => globalProperty.value.global_setting.color_by_range)
  const getHighlightedElements = computed(() => highlighedElement.value)
  const getSelectedNavigationTab = computed(() => selectedNavigationTab.value)
  const getSelectedElements = computed(() => selectedElements.value)
  const getSelectedWidget = computed(() => selectedWidget.value)
  const getWidgets = computed(() => widgets.value)
  const getDashboardWidgets = computed(() => {
    let filtered = widgets.value.filter((widget)=> {
      if(  widget.instance_setting === undefined
          || widget.instance_setting.isPanelWidget === undefined
         || widget.instance_setting.isPanelWidget === null
         || widget.instance_setting.isPanelWidget === false){
        return widget;
      }
    })
    return filtered
  })
  const getwidgetIDsToUpdate = computed(() => widgetIDsToUpdate.value)
  const getWidgetsToDelete = computed(() => widgetsToDelete.value)

  const getAllPageStates = computed(() => allPageStates.value)
  const getPagesStructuredData = computed(() => pagesStructuredData.value)
  const getSelectedPage = computed(() => selectedPage.value)
  const getSelectedPageStateId = computed(() => selectedPageStateId.value)

  const getLoadingScreen = computed(() => loadingScreen.value)
  const getDatasetLoading = computed(() => datasetLoading.value)

  //Actions
  function toggleEditMode() {
    editMode.value = !editMode.value;
  }

  function togglePresave() {
    presave.value = !presave.value;
  }

  async function setGlobalProperty(newGlobalProperty) {
    globalProperty.value = newGlobalProperty
  }

  async function setGlobalColorByAttribute(newColorByAttribute) {
    globalProperty.value.global_setting.color_by_attribute = newColorByAttribute
  }

  async function setGlobalColorByGradient(newColorByGradient) {
    globalProperty.value.global_setting.color_by_gradient = newColorByGradient
  }

  async function setGlobalColorByRange(newColorByRange) {
    globalProperty.value.global_setting.color_by_range = newColorByRange
  }

  function setGlobalHighlightColor(color){
    globalProperty.value.global_setting.highlight_color = color
  }

  function setGlobalSelectionColor(color){
    globalProperty.value.global_setting.selection_color = color
  }

  function setGlobalSignificantDigits(digits){
    globalProperty.value.global_setting.significant_digits = digits
  }

  async function setPagesStructuredData(id) {
    const authService = getInstance();
    let params = {
      notebookId: id,
      urlType: router.currentRoute._value.name
    };
    let all_pages = await authService.$api.get(
      `/api/pages/get-all-for-notebook-structured`,{ params });

      let newArray = all_pages.data.data.pages.sort((e, q) => {
        {
          return q.order - e.order;
        }
      });
      let q = all_pages.data.data.pages.map((e) => {
        return e.childNodes.sort((a, b) => {
          return b.order - a.order;
        });
      });

    pagesStructuredData.value = all_pages.data.data.pages;
    allPageStates.value = all_pages.data.data.page_states;
    
    //Set selected page, if name was changed
    pagesStructuredData.value.forEach(page => {
      if (page._id == selectedPage.value._id) {
        if (page.name != selectedPage.value.name){
          setSelectedPage(page)
        }
        page.childNodes.forEach(subPage => {
          if (subPage.name != selectedPage.value.name){
            setSelectedPage(subPage)
          }
        })
      }
    })
  }

  async function setSelectedPage(newPage) {
    selectedPage.value = newPage
  }

  function setSelectedWidget(widget){
    selectedWidget.value = widget
  }

  async function setWidgets(newWidgets) {
    widgets.value = newWidgets
  }

  function addWidget(newWidget) {
    widgets.value.push(newWidget)
  }

  async function setwidgetIDsToUpdate(widgets){
    widgetIDsToUpdate.value = widgets
  }

  async function setWidgetsToDelete(widgets){
    widgetsToDelete.value = widgets
  }

  function addToSelectedElements(element) {
    const index = selectedElements.value.indexOf(element);
    if (index === -1) {
      selectedElements.value.push(element);
    }
  }

  function addRangeToSelectedElements(elements) {
    for (const element of elements) {
      if (!selectedElements.value.includes(element)) {
        selectedElements.value.push(element);
      }
    }
  }

  function removeFromSelectedElements(element) {
    const index = selectedElements.value.indexOf(element);
    if (index !== -1) {
      selectedElements.value.splice(index, 1);
    }
  }

  function setHighlightedElements(element) {
    highlighedElement.value = element;
  }

  async function updateAllPageStates(updatedPageState){
    let targetStateIndex = null;
    allPageStates.value.forEach((pageState, index) => {
      if (pageState._id == updatedPageState._id) {
        targetStateIndex = index;
      }
    });
    if(targetStateIndex != null){
      allPageStates.value[targetStateIndex] = updatedPageState;
    }
  }

  function setLoadingScreen(val){
    loadingScreen.value = val
  }
  function setDatasetLoading(val){
    datasetLoading.value = val
  }

  return {
    allPageStates,
    editMode,
    presave,
    highlighedElement,
    globalProperty,
    globalPropertyUpdated,
    pagesStructuredData,
    selectedElements,
    selectedNavigationTab,
    selectedPage,
    selectedPageStateId,
    selectedWidget,
    widgets,
    widgetIDsToUpdate,
    widgetsToDelete,
    loadingScreen,
    datasetLoading,

    getAllPageStates,
    getEditMode,
    getPresave,
    getDashboardWidgets,
    getHighlightedElements,
    getGlobalProperty,
    getGlobalSignificantDigits,
    getGlobalColorByAttribute,
    getGlobalColorByGradient,
    getGlobalColorByRange,
    getGlobalPropertyUpdated,
    getPagesStructuredData,
    getSelectedElements,
    getSelectedNavigationTab,
    getSelectedPage,
    getSelectedPageStateId,
    getSelectedWidget,
    getWidgets,
    getWidgetsToDelete,
    getwidgetIDsToUpdate,
    getLoadingScreen,
    getDatasetLoading,

    setHighlightedElements,
    setGlobalProperty,
    setGlobalColorByAttribute,
    setGlobalColorByGradient,
    setGlobalColorByRange,
    setGlobalHighlightColor,
    setGlobalSelectionColor,
    setGlobalSignificantDigits,
    setPagesStructuredData,
    setSelectedPage,
    setSelectedWidget,
    setWidgets,
    addWidget,
    setWidgetsToDelete,
    setwidgetIDsToUpdate,
    addToSelectedElements,
    addRangeToSelectedElements,
    removeFromSelectedElements,
    toggleEditMode,
    togglePresave,
    updateAllPageStates,
    setLoadingScreen,
    setDatasetLoading
  }
})