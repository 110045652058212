<template>
  <!-- Gradient Selection -->
  <v-menu 
    offset-y 
    light 
    max-height="200px"
  >
    <template v-slot:activator="{ props}">
      <!--Gradent Select Button with Gradient Preview at Bottom. -->
      <v-btn 
        v-bind="props"
        class="text-left" 
        v-if="currentPalette"  
        width="100%"
        :size="denseMode ? 'small' : ''"
        :block="denseMode"
        :variant="!denseMode ? 'outlined' : 'elevated'"
        :height="!denseMode ? '44px' : ''"
        :color="!denseMode ? 'darkGrey' : ''"
        :elevation="!denseMode ? '0' : '1'"
        style="margin-top:4px; background-size:100% 15%; background-repeat:no-repeat; background-position:bottom;" 
        :style="
          'background-image:linear-gradient(to right,' +
          currentPalette.join(',') + ')'"
      >
        <v-row class="d-flex justify-space-between">
          <span 
            class="text-truncate pr-2"
            :class="denseMode ? 'pb-2' : 'pt-1'" 
            style="font-weight:400;"
            :style="denseMode ? 'font-size: small;':'font-size: medium;'"
          > 
            {{message}}
          </span>
          <!-- Button Content. -->
          <v-icon class="pb-1 text-truncate"  
        :size="denseMode ? 'x-small' : ''"
        color="darkGrey"
          >
            {{ icons.arrowDown }}
          </v-icon>
        </v-row>
      </v-btn>
    </template>
    <!-- Dropwdown List of Gradients. -->
    <v-list max-height="800">
      <v-list-item class="px-2 my-0" 
        v-for="(palette, index) in palettes"
        :key="index"
        @click="setNewGradient(index)"
      >
        <!-- Gradient. -->
        <v-btn class="ma-0 pa-0" 
          size="small" 
          style="white-space: nowrap; 
            border-width: 0px; 
            height:20px; 
            width: 100%" 
          :style="'background:linear-gradient(to right,' + 
            palette.colors.join(',') + ')'"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>

import * as gradients from "@/utilities/gradients.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  setup() {
    return {icons}
  },
  components: {},
  props: {
    message:{
      type:String,
      default:"Gradient"
    },
    currentPalette:{
      type:Array
    },
    denseMode:{
      type:Boolean, 
      default:true
    }
  },
  data: () => ({
    palettes: [],
  }),
  created() {
    this.loadGradientPalette();
  },
  mounted(){
    this.setDeafaultGradient();
  },
  methods: {
    loadGradientPalette() {
      //Retrieving gradients from Utilties.
      const gradientNames = Object.keys(gradients); 
      const gradientColors = Object.values(gradients);
      const gradientPalettes = [];

      //Build gradients objects.
      for (var i = 0; i < gradientNames.length; i++) {
        gradientPalettes.push({
          name: gradientNames[i],
          colors: gradientColors[i],
        });
      }

      //Assign Props.
      this.palettes = gradientPalettes;
    },
    setDeafaultGradient(defaultPallet=2){
      if(this.currentPalette.length === 0){
        this.setNewGradient(defaultPallet)
      }
    },
    setNewGradient(i) {
      //Set new current pallet. 
      let newPalette = this.palettes[i].colors;

      //Rais Event to notify Parent that gradient has changed. 
      this.$emit("new-palette-set", newPalette); 
    },
  },
};
</script>
<style scoped>
.col {
    padding: 0px !important
}
.v-btn--outlined {
    border: thin solid #acabab;
}
</style>